import { useMemo } from 'react'
import { Breadcrumbs } from '@mui/material'
import { useMatches } from 'react-router-dom'
import { RouteHandle, RouteNames } from '@dis/routing'
import { useAppSelector } from '@dis/redux'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/redux/src/security/securityTypes'
import { Link } from './Link'
import { styles } from './styles'

export const Breadcrumb = () => {
  const userRole = useAppSelector(selectUserRole)

  const matches = useMatches()

  const crumbs = useMemo(
    () =>
      matches
        .filter((match) => {
          const handle = match.handle as RouteHandle | undefined
          const handleName = handle?.name as RouteNames | undefined

          if (
            handleName === 'tenants' &&
            !!userRole &&
            [UserRole.User, UserRole.PowerUser, UserRole.TenantAdmin].includes(userRole)
          ) {
            return false
          }

          return !!handle?.crumb
        })
        .map((match) => {
          const handle = match.handle as RouteHandle
          return { ...(handle?.crumb?.() || {}), routeKey: handle?.name as RouteNames }
        }),
    [matches, userRole],
  )

  return (
    <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.root}>
      {crumbs.map(({ icon, routeKey, routeToKey }) => {
        return <Link key={routeKey} routeKey={routeKey} routeToKey={routeToKey} icon={icon} />
      })}
    </Breadcrumbs>
  )
}
