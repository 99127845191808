import { Dispatch, SetStateAction, useCallback } from 'react'
import { exportDocument } from '@dis/api'
import { LoadingInfoType } from '@dis/modules/src/ApplicationMenu/ApplicationMenu'
import { useAppSelector } from '@dis/redux'
import { selectLanguage } from '@dis/redux/src/user/selectors'

export const useExportTenantUsers = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const localeId = useAppSelector(selectLanguage)
  const handleExportTenantUsers = useCallback(async () => {
    const response = await exportDocument('tenantUsersXls', { localeId })

    setLoadingInfo({ isLoading: false, status: response?.status })
  }, [setLoadingInfo, localeId])

  return { handleExportTenantUsers }
}
