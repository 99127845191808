import { Language } from '@dis/types/src/GeneralTypes'
import {
  AllPossibleSorters,
  CommonFilterType,
  CreatedUpdatedTitleSortType,
} from '@dis/types/src/PopoverFilterSortTypes'

export const commonSortByOptions: CreatedUpdatedTitleSortType[] = [
  'titleAZ',
  'titleZA',
  'createdOldest',
  'createdNewest',
  'updatedOldest',
  'updatedNewest',
]

let currentLocale: Language | undefined = undefined

type Sortable = {
  created?: number
  id?: number
  name?: string
  updated?: number
}

const sorters: Record<AllPossibleSorters, (a: Sortable, b: Sortable) => number> = {
  createdNewest: (a, b) => (b?.created ?? 0) - (a?.created ?? 0),
  createdOldest: (a, b) => (a?.created ?? 0) - (b?.created ?? 0),
  idAsc: (a, b) => (a?.id ?? 0) - (b?.id ?? 0),
  idDesc: (a, b) => (b?.id ?? 0) - (a?.id ?? 0),
  titleAZ: (a, b) =>
    (a?.name ?? '').localeCompare(b?.name ?? '', currentLocale, { sensitivity: 'variant' }),
  titleZA: (a, b) =>
    (b?.name ?? '').localeCompare(a?.name ?? '', currentLocale, { sensitivity: 'variant' }),
  updatedNewest: (a, b) => (b?.updated ?? 0) - (a?.updated ?? 0),
  updatedOldest: (a, b) => (a?.updated ?? 0) - (b?.updated ?? 0),
}

export const applySortAndFilter = <DT extends Sortable[]>({
  data,
  sortBy,
  searchValue,
  filters,
  locale,
}: {
  data: DT
  filters?: CommonFilterType
  locale?: Language
  searchValue?: string
  sortBy?: AllPossibleSorters
}): DT => {
  currentLocale = locale

  let ret = searchValue
    ? data.filter(({ name }) => name?.toLocaleLowerCase().includes(searchValue.toLowerCase()))
    : data

  if (filters) {
    ret = ret.filter((item) => {
      const published = 'published' in item ? item.published : false

      let flatFilters: Record<string, boolean> = {}

      if (Array.isArray(filters)) {
        filters?.forEach((nestedFilters) => {
          Object.entries(nestedFilters).forEach(([key, value]) => {
            flatFilters[key] = value
          })
        })
      } else if (filters && typeof filters === 'object') {
        flatFilters = filters
      }

      if (published && (flatFilters.published || flatFilters.visible)) return true
      return !!(
        !published &&
        (flatFilters.inProgress || flatFilters.invisible || flatFilters.hidden)
      )
    })
  }

  if (sortBy) {
    const chosenSorter = sorters[sortBy]

    ret = sortBy ? [...ret].sort(chosenSorter) : ret
  }

  return ret as DT
}

export const applySort = <DT extends Sortable[]>(
  data: DT,
  sortBy?: CreatedUpdatedTitleSortType,
  locale?: Language,
): DT => {
  if (!sortBy) return data

  currentLocale = locale
  const chosenSorter = sorters[sortBy]
  const sorted = [...data].sort(chosenSorter)

  return sorted as DT
}
