import { css } from '@emotion/css'
import { colors } from './colors'

const redIconStyles = css`
  g {
    fill: ${colors.semantic.red};
  }
`

const blueIconStyles = css`
  path {
    fill: ${colors.kpmg.blue};
  }
`

const buttonsContainerOpacity0 = css`
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: linear;
  opacity: 0;
`
const arrowsOpacity0 = css`
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: linear;
  opacity: 0;
`

const buttonsContainerTransition = css`
  &:hover {
    ${'.' + buttonsContainerOpacity0} {
      transition-duration: 200ms;
      transition-property: opacity;
      opacity: 1;
    }
  }
`

const arrowsContainerTransition = css`
  &:hover {
    ${'.' + arrowsOpacity0} {
      transition-duration: 500ms;
      transition-property: opacity;
      opacity: 1;
    }
  }
`

export const headerComponentWrapper = css`
  border-bottom: 1px solid ${colors.gray.gray10};
  background-color: ${colors.background.whiteBlue};
  cursor: pointer;
  display: flex;
`

export const styles = {
  arrowsContainerTransition,
  arrowsOpacity0,
  blueIconStyles,
  buttonsContainerOpacity0,
  buttonsContainerTransition,
  redIconStyles,
}
