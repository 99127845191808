import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import SuccessNotificationIcon from '@dis/assets/src/icons/SuccessNotificationIcon.svg'
import { colors } from '@dis/styles/src/colors'
import Close from '@dis/assets/src/icons/Close.svg'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { RoundIconButton, SvgImage } from '@dis/components'
import { selectNotifications } from '@dis/redux/src/notification/notificationSelectors'
import { styles } from './styles'

export const CentralNotificationPopup: React.FC = () => {
  const notifications = useAppSelector(selectNotifications)

  const handleClose = (uuid: string) => () => {
    dispatchedActions.centralNotification.hideNotification(uuid)
  }

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const timerId = setInterval(() => {
        const now = Date.now()
        const expiredNotifications = notifications.filter((x) => x.hideAtTime <= now)
        expiredNotifications.forEach((item) => {
          dispatchedActions.centralNotification.hideNotification(item.uuid)
        })
      }, 100)
      return () => clearInterval(timerId)
    }
  }, [notifications, notifications.length])

  if (!notifications || notifications.length === 0) return null

  return (
    <div className={styles.notificationBox}>
      <Stack>
        {notifications.map((item) => {
          return (
            <div className={styles.notification} key={item.uuid}>
              <SvgImage src={SuccessNotificationIcon} color={colors.semantic.green} />
              {item.content}
              <RoundIconButton
                className={styles.closeButton}
                icon={<SvgImage src={Close} color={colors.gray.gray40} />}
                size="small"
                onClick={handleClose(item.uuid)}
              />
            </div>
          )
        })}
      </Stack>
    </div>
  )
}
