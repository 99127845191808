import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AcceptIcon from '@dis/assets/src/icons/AcceptIcon.svg'
import { tKeys } from '@dis/languages'
import BinIcon from '@dis/assets/src/icons/BinIcon.svg'
import { colors } from '@dis/styles/src/colors'
import ProgressIcon from '@dis/assets/src/icons/ProgressIcon.svg'
import { Paragraph, SvgImage } from '@dis/components'

type StatusButtonProps = {
  disabled?: boolean
  isDeleted?: boolean
  isDone?: boolean
  onClick?: VoidFunction
  withText?: boolean
}

export const StatusButton = ({
  disabled = false,
  onClick,
  isDone,
  withText,
  isDeleted,
}: StatusButtonProps) => {
  const { t } = useTranslation()

  const published = isDone && !isDeleted && <SvgImage color={colors.gray.gray0} src={AcceptIcon} />
  const inProgress = !isDone && !isDeleted && (
    <SvgImage color={colors.gray.gray0} src={ProgressIcon} />
  )
  const deleted = isDeleted && <SvgImage color={colors.gray.gray0} src={BinIcon} />

  const backgroundColor =
    isDone && !isDeleted
      ? colors.semantic.green
      : !isDone && !isDeleted
      ? colors.kpmg.pacificBlue
      : isDeleted
      ? colors.semantic.red
      : ''

  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor,
        borderRadius: '1.5rem',
        cursor: isDeleted ? 'not-allowed' : 'pointer',
        minWidth: '36px',
      }}>
      {published}
      {inProgress}
      {deleted}
      {withText && (
        <Paragraph variant="small" color={colors.gray.gray0} textTransform="uppercase" paddingX={1}>
          {isDone && !isDeleted
            ? t(tKeys.statusButton.published)
            : !isDone && !isDeleted
            ? t(tKeys.statusButton.inProgress)
            : isDeleted
            ? t(tKeys.statusButton.deleted)
            : ''}
        </Paragraph>
      )}
    </IconButton>
  )
}
