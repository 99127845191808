import React, { FC } from 'react'
import { useAtlasPermissions } from '@dis/hooks/src/useAtlasPermissions'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { dateHandler } from '@dis/utils'
import { cx } from '@emotion/css'
import { Paragraph, RoundIconButton, SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { tKeys } from '@dis/languages'
import GreenTickIcon from '@dis/assets/src/icons/GreenTickIcon.svg'
import EditPencilIcon from '@dis/assets/src/icons/EditPencilIcon.svg'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@dis/assets/src/icons/RefreshIcon.svg'
import DeleteIcon from '@dis/assets/src/icons/DeleteIcon.svg'
import MoveIcon from '@dis/assets/src/icons/MoveIcon.svg'
import ShareIcon from '@dis/assets/src/icons/ShareIcon.svg'
import DuplicateIcon from '@dis/assets/src/icons/CopyIcon.svg'
import { TitleController } from './TitleController'
import {
  atlasFolderJourneyContainer,
  centralIcon,
  centralIconTransition,
  iconsController,
  roundIcons,
  roundIconsCopy,
  roundIconsDelete,
  roundIconsMove,
  roundIconsRefresh,
  roundIconsShare,
  roundIcon,
  textControllerStyles,
} from './styles'
import { icons, MAX_ATLAS_FOLDER_VISIBLE_LENGTH } from './utils'

export interface AtlasFolderJourneyProps {
  atlasId?: number
  atlasNameForRecycleBin?: string
  created?: number
  deleted?: number
  folderId?: number
  maxLength?: number
  name?: string
  onClick: VoidFunction
  onClickDeleteIcon?: VoidFunction
  onClickDuplicateIcon?: VoidFunction
  onClickMoveIcon?: VoidFunction
  onClickRefreshIcon?: VoidFunction
  onClickShareIcon?: VoidFunction
  published?: boolean
  refetch?: VoidFunction
  type: 'atlas' | 'folder' | 'journey'
  updated?: number
}

export const AtlasFolderJourney: FC<AtlasFolderJourneyProps> = ({
  atlasNameForRecycleBin,
  atlasId,
  created,
  deleted,
  folderId,
  maxLength,
  name,
  onClick,
  onClickRefreshIcon,
  onClickDeleteIcon,
  onClickMoveIcon,
  onClickShareIcon,
  onClickDuplicateIcon,
  published,
  refetch,
  type,
  updated,
}) => {
  const atlasPermissions = useAtlasPermissions({ atlasIds: [atlasId || 0] })

  const canEdit = atlasId ? atlasPermissions[atlasId].canEdit : false

  let canDelete = false
  if (atlasId) {
    if (type === 'atlas') {
      canDelete = atlasPermissions[atlasId].canDelete
    } else if (atlasPermissions[atlasId].canEdit) {
      canDelete = true
    }
  }

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const { t } = useTranslation()

  return (
    <div data-testid="atlas-folder-journey" className={atlasFolderJourneyContainer}>
      <div className={iconsController}>
        {/*
         * ---
         * Central icon
         * ---
         */}
        <div
          className={cx(centralIcon, {
            [centralIconTransition.extended]: !onClickDeleteIcon && !onClickRefreshIcon,
            [centralIconTransition.simple]: !(!onClickDeleteIcon && !onClickRefreshIcon),
          })}
          onClick={onClick}>
          {type === 'atlas' && icons.atlas}
          {type === 'folder' && icons.folder}
          {type === 'journey' && icons.journey}
        </div>

        {/*
         * ---
         * Round icons
         * ---
         */}
        {onClickRefreshIcon && (
          <RoundIconButton
            icon={
              <div title={t(tKeys.roundIconToolTip.restore)}>
                <SvgImage color={colors.text} src={RefreshIcon} className={roundIcon} />
              </div>
            }
            size="small"
            outline="grey"
            onClick={onClickRefreshIcon}
            className={`${roundIcons} ${roundIconsRefresh}`}
          />
        )}
        {canDelete && onClickDeleteIcon && (
          <RoundIconButton
            icon={
              <div title={t(tKeys.roundIconToolTip.delete)}>
                <SvgImage color={colors.semantic.red} src={DeleteIcon} className={roundIcon} />
              </div>
            }
            data-testid="atlas-folder-journey-delete-btn"
            size="small"
            outline="grey"
            className={`${roundIcons} ${roundIconsDelete}`}
            onClick={onClickDeleteIcon}
          />
        )}
        {canEdit && onClickMoveIcon && (
          <RoundIconButton
            icon={
              <div title={t(tKeys.roundIconToolTip.move)}>
                <SvgImage color="" src={MoveIcon} className={roundIcon} />
              </div>
            }
            size="small"
            outline="grey"
            onClick={onClickMoveIcon}
            className={`${roundIcons} ${roundIconsMove}`}
          />
        )}
        {onClickShareIcon && (
          <RoundIconButton
            icon={
              <div title={t(tKeys.roundIconToolTip.share)}>
                <SvgImage color="" src={ShareIcon} className={roundIcon} />
              </div>
            }
            size="small"
            outline="grey"
            onClick={onClickShareIcon}
            className={`${roundIcons} ${roundIconsShare}`}
          />
        )}
        {canEdit && onClickDuplicateIcon && (
          <RoundIconButton
            icon={
              <div title={t(tKeys.roundIconToolTip.duplicate)}>
                <SvgImage color="" src={DuplicateIcon} className={roundIcon} />
              </div>
            }
            size="small"
            outline="grey"
            onClick={onClickDuplicateIcon}
            className={`${roundIcons} ${roundIconsCopy}`}
          />
        )}
      </div>

      {(type === 'atlas' || type === 'folder') && canEdit && (
        <TitleController
          atlasId={atlasId}
          folderId={folderId}
          maxLength={maxLength}
          name={name}
          refetch={refetch}
          type={type}
        />
      )}

      {(type === 'journey' || !canEdit) && (
        <Paragraph
          color={colors.text}
          className={cx(
            textControllerStyles.title.root,
            textControllerStyles.title.editableTitleStyle,
          )}
          variant="medium"
          title={name && name?.length > MAX_ATLAS_FOLDER_VISIBLE_LENGTH ? name : undefined}>
          {name}
        </Paragraph>
      )}

      <div className={textControllerStyles.container}>
        {created && (
          <Paragraph color={colors.gray.gray40} variant="tiny" textAlign="center">
            {t(tKeys.textController.dateOfCreation)} {dateHandler(created, isRtl)}
          </Paragraph>
        )}

        {updated && (
          <Paragraph color={colors.gray.gray40} variant="tiny" textAlign="center">
            {t(tKeys.textController.lastChange)} {dateHandler(updated, isRtl)}
          </Paragraph>
        )}

        {deleted && (
          <Paragraph
            color={colors.gray.gray40}
            variant="tiny"
            textAlign="center"
            className={textControllerStyles.upperFirstChar}>
            {t(tKeys.recycleBin.deleted)} {dateHandler(deleted, isRtl)}
          </Paragraph>
        )}

        {type === 'journey' && published !== undefined && (
          <div className={textControllerStyles.journeyStatus}>
            <SvgImage src={published ? GreenTickIcon : EditPencilIcon} />
            <Paragraph
              variant="tiny"
              className={textControllerStyles.bottomText}
              color={published ? colors.semantic.green : colors.gray.gray40}>
              {t(published ? tKeys.textController.published : tKeys.textController.inProgress)}
            </Paragraph>
          </div>
        )}

        {!!atlasNameForRecycleBin && (
          <Paragraph
            className={textControllerStyles.atlasNameForRecycleBin}
            color={colors.gray.gray40}
            variant="tiny"
            textAlign="center"
            title={`${t(tKeys.common.atlas)}: ${atlasNameForRecycleBin}`}>
            {atlasNameForRecycleBin}
          </Paragraph>
        )}
      </div>
    </div>
  )
}
