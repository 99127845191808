export const APP_DOMAIN = 'https://kpmgdiscovery.com'

export const BE_REST_API_URL = '/api/eventhandler'

export const MB_IN_B = 1024 * 1024

export const MAX_FILE_SIZE_B = 5 * MB_IN_B

export const MAX_COST_VALUE = 999_999_999_999_999

// It is used for disabling dnd over the element this class is passed to.
export const DISALLOW_DND = 'disallow_dnd_reserved_class'

export const GRID_WRAPPER_ID = 'discovery-grid-wrapper'

/**
 * Universal string length for names
 */
export const MAX_NAME_LENGTH = 300

/**
 * Universal string length, e.g. description
 */
export const MAX_STRING_LENGTH = 1_000

/**
 * Basic font-size for REM units.
 * To change this value globally it's necessary to set a new value here and also in the index.scss.
 */
export const BASIC_FONT_SIZE = 16 // px

/**
 * string length, e.g. tenant description
 */
export const MAX_TENANT_DESCRIPTION = 130

/**
 * string length, e.g. tenant name
 */
export const MAX_TENANT_NAME = 30

export const MAX_CATEGORY_NAME = 15
