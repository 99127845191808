import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { LoadingInfoType } from '@dis/modules/src/ApplicationMenu/ApplicationMenu'
import { exportDocument } from '@dis/api'

export const useExportXml = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const tenantId = useAppSelector(selectSelectedTenantId)
  const journeyId = useAppSelector(selectSelectedJourneyId)
  const localeId = useAppSelector(selectLanguage)
  const handleExportXml = useCallback(async () => {
    const response = await exportDocument('journeyXml', { journeyId, localeId, tenantId })

    setLoadingInfo({ isLoading: false, status: response?.status })
  }, [journeyId, localeId, setLoadingInfo, tenantId])

  return { handleExportXml }
}
