import { Dispatch, SetStateAction, useCallback } from 'react'
import { ListItemIcon, MenuItem } from '@mui/material'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { Language } from '@dis/types/src/GeneralTypes'
import { theme } from '@dis/styles'
import { NestedMenuItem } from 'mui-nested-menu'
import { useSendEmail } from '@dis/hooks/src/useSendEmail'
import { useNavTo } from '@dis/hooks'
import { useMatch, useParams } from 'react-router-dom'
import {
  selectIsFeatureConnectedCapabilitiesAllowed,
  selectApplicationMenuPermission,
  selectIsArabicLanguageAllowed,
  selectUserRole,
} from '@dis/redux/src/security/selectors'
import { useTranslation } from 'react-i18next'
import { useExportXml } from '@dis/hooks/src/useExportXml'
import { routeFullPaths } from '@dis/routing'
import { useShareJourney } from '@dis/hooks/src/useShareJourney'
import { useChangeLanguage } from '@dis/hooks/src/useChange'
import { useExportTenantUsers } from '@dis/hooks/src/useExportTenantUsers'
import { useExportXlsx } from '@dis/hooks/src/useExportXlsx'
import { useExportPDF } from '@dis/hooks/src/useExportPDF'
import { useImportToXlsx } from '@dis/hooks/src/useImportToXlsx'
import LeftArrowIcon from '@dis/assets/src/icons/LeftArrowIcon.svg'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import RightArrowIcon from '@dis/assets/src/icons/RightArrowIcon.svg'
import { tKeys } from '@dis/languages'
import { Paragraph, SvgImage } from '@dis/components'
import { LANGUAGES } from '@dis/constants'
import { selectSelectedIsTemplateTenant } from '@dis/redux/src/tenants/tenantsSelectors'
import { SubmenuDataItem, MenuDataItem } from './constants'
import { styles } from './styles'
import { ApplicationMenuTypes } from './utils'
import { LoadingInfoType } from './ApplicationMenu'

type Props = {
  handleClose: () => void
  menuItem: MenuDataItem
  opened: boolean
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}

export const ApplicationMenuItem = ({
  menuItem: { items, type, icon, title, navigateTo, onClick, requireTenant },
  opened,
  handleClose,
  setLoadingInfo,
}: Props) => {
  const isRtl = useAppSelector(selectIsLanguageRtl)
  const isFeatureConnectedCapabilitiesAllowed = useAppSelector(
    selectIsFeatureConnectedCapabilitiesAllowed,
  )
  const isArabicLanguageAllowed = useAppSelector(selectIsArabicLanguageAllowed)
  const permission = useAppSelector(selectApplicationMenuPermission(type))
  const isTemplateTenant = useAppSelector(selectSelectedIsTemplateTenant)
  const userRole = useAppSelector(selectUserRole)

  const { navigate } = useNavTo()
  const { handleSendEmail } = useSendEmail()
  const { handleShareJourney } = useShareJourney()
  const { handleExportTenantUsers } = useExportTenantUsers({ setLoadingInfo })
  const { handleExportXlsx } = useExportXlsx({ setLoadingInfo })
  const { handleExportPDF } = useExportPDF({ setLoadingInfo })
  const { handleImportXlsx } = useImportToXlsx({ setLoadingInfo })
  const { handleExportXml } = useExportXml({ setLoadingInfo })
  const { send: changeLanguage } = useChangeLanguage()
  const { tenantId, atlasId, folderId, journeyId } = useParams()
  const { t } = useTranslation()

  const isJourney = !!useMatch(routeFullPaths?.journey || '')
  const isJourneyFolder = !!useMatch(routeFullPaths?.journeyFolder || '')
  const isPersona = !!useMatch(routeFullPaths?.persona || '')
  const isBacklog = !!useMatch(routeFullPaths?.userStory || '')

  const atlas = atlasId ? parseInt(atlasId) : undefined
  const folder = folderId ? parseInt(folderId) : undefined
  const journey = journeyId ? parseInt(journeyId) : undefined
  const isNotJourney = !isJourney && !isJourneyFolder

  const canRender =
    (!requireTenant || (tenantId !== null && tenantId !== undefined && requireTenant)) &&
    !!permission

  const isEmptyComponent =
    !canRender ||
    (isNotJourney && type === ApplicationMenuTypes.SendEmail) ||
    (isNotJourney && type === ApplicationMenuTypes.ShareJourney) ||
    (isNotJourney && !isBacklog && type === ApplicationMenuTypes.ExportToXLSX) ||
    (isNotJourney && type === ApplicationMenuTypes.ImportToXLSX) ||
    (isNotJourney && type === ApplicationMenuTypes.ExportXML) ||
    (isNotJourney && !isPersona && type === ApplicationMenuTypes.ExportToPdf)

  const onMenuItemClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
    if (navigateTo) {
      navigate(navigateTo)
    }
    switch (type) {
      case ApplicationMenuTypes.SendEmail:
        handleSendEmail()
        handleClose()
        break
      case ApplicationMenuTypes.Logout:
        dispatchedActions.security.logoutThisDevice()
        handleClose()
        break
      case ApplicationMenuTypes.LogoutAllDevices:
        dispatchedActions.security.logoutAllDevices()
        handleClose()
        break
      case ApplicationMenuTypes.ExportXML:
        handleExportXml()
        setLoadingInfo({ isLoading: true })
        handleClose()
        break
      case ApplicationMenuTypes.ShareJourney:
        handleShareJourney(atlas, folder, journey)()
        handleClose()
        break
      case ApplicationMenuTypes.ExportToXLSX:
        handleExportXlsx()
        setLoadingInfo({ isLoading: true })
        handleClose()
        break
      case ApplicationMenuTypes.ImportToXLSX:
        handleImportXlsx()
        handleClose()
        break
      case ApplicationMenuTypes.ExportToPdf:
        handleExportPDF()
        setLoadingInfo({ isLoading: true })
        handleClose()
        break
      default:
        handleClose()
        break
    }
  }, [
    onClick,
    navigateTo,
    type,
    navigate,
    handleSendEmail,
    handleClose,
    handleExportXml,
    setLoadingInfo,
    handleShareJourney,
    handleExportXlsx,
    handleImportXlsx,
    handleExportPDF,
    atlas,
    folder,
    journey,
  ])

  const onSubmenuItemClick = useCallback(
    (menu: SubmenuDataItem) => () => {
      handleClose()
      if (type === ApplicationMenuTypes.Language && menu.value) {
        changeLanguage(menu.value as Language)
      } else if (menu.navigateTo) {
        navigate(menu.navigateTo)
      } else if (
        type === ApplicationMenuTypes.Configuration &&
        menu.type === ApplicationMenuTypes.ExportTenantUsers
      ) {
        handleExportTenantUsers()
      }
    },
    [handleClose, navigate, type, changeLanguage, handleExportTenantUsers],
  )

  const filteredItems =
    items?.filter((item) => {
      if (item.requireTenant && !tenantId) {
        return false
      }

      if (type === 'language' && item.value === LANGUAGES.ar) return isArabicLanguageAllowed

      if (item.navigateTo === 'capabilityManagement' && !isFeatureConnectedCapabilitiesAllowed)
        return false

      // Don't show global capability management item if app is inside a tenant
      if (item.navigateTo === 'globalCapabilityManagement' && !!tenantId) return false

      // Don't show global user management item if app is inside a tenant
      if (item.navigateTo === 'globalUserManagement' && !!tenantId) return false

      // Don't show global template management item if app is inside a tenant
      if (item.navigateTo === 'globalTemplateManagement' && !!tenantId) return false

      if (item.navigateTo === 'templateManagement' && isTemplateTenant && userRole !== 'kpmgadmin')
        return false

      return typeof permission === 'object' && !!permission[item.type]
    }) || []

  if (items?.length && !filteredItems.length) {
    return <></>
  }

  if (isEmptyComponent) {
    return <></>
  }

  return items ? (
    <NestedMenuItem
      key={title}
      className={styles.nestedMenuItem}
      leftIcon={<ListItemIcon>{icon}</ListItemIcon>}
      rightIcon={
        <div className={styles.languageContainer}>
          {type === 'language' && (
            <Paragraph variant="small">{t(tKeys.applicationMenu.languages)}</Paragraph>
          )}
          <div className={styles.iconsContainer}>
            {isRtl ? (
              <SvgImage width="9px" height="9px" src={LeftArrowIcon} />
            ) : (
              <SvgImage width="9px" height="9px" src={RightArrowIcon} />
            )}
          </div>
        </div>
      }
      label={t(title)}
      parentMenuOpen={opened}
      MenuProps={{
        className: styles.nestedMenu,
        sx: { left: isRtl ? `-${theme.spacing(57)}` : `-${theme.spacing(59)}` },
      }}>
      {filteredItems?.map((submenuItem) => (
        <MenuItem onClick={onSubmenuItemClick(submenuItem)} key={`${title}_${submenuItem.title}`}>
          <Paragraph variant="small">{t(submenuItem.title)}</Paragraph>
        </MenuItem>
      ))}
    </NestedMenuItem>
  ) : (
    <MenuItem onClick={onMenuItemClick} key={title}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Paragraph variant="small">{t(title)}</Paragraph>
    </MenuItem>
  )
}
