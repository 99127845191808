import { css } from '@emotion/css'
import { colors, shadows, theme, typographyFontWeight } from '@dis/styles'
import { shadowClasses } from '@dis/styles/src/boxShadows'

export const roundIconDelete = css`
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  bottom: 3.5rem;
  right: 3.5rem;
`

const threeDots = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

const actionsContainer = css`
  opacity: 0;
  z-index: -1;
  background-image: linear-gradient(${colors.transparent} 15%, ${colors.gray.gray0} 55%);
  transition: opacity ease-in-out 0.2s, z-index ease-in-out 0s 0.2s;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: flex-end;
  padding: ${theme.spacing(6)};
  gap: ${theme.spacing(3)};
`

export const styles = {
  actionsContainer,
  body: css`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
  `,

  createdContainer: css`
    opacity: 1;
    z-index: 1;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;

    & > * {
      margin-top: ${theme.spacing(4)};
    }
  `,
  descContainer: css`
    flex: 1 1 auto;
    padding-top: ${theme.spacing(2)};
  `,
  editTemplateModal: css`
    display: flex;
    flex-direction: column;
  `,
  editTenantModal: css`
    display: flex;
    flex-direction: column;
  `,
  font: css`
    color: ${colors.semantic.green};
  `,
  middleContainer: css`
    flex: 1 1 auto;
    padding-top: ${theme.spacing(2)};
    position: relative;
  `,
  modalCheckbox: css`
    align-self: start;
    margin-bottom: -${theme.spacing(6)};
    margin-inline-start: -${theme.spacing(6)};
    margin-top: ${theme.spacing(6)};
  `,
  nameContainer: css`
    cursor: default;
  `,
  paper: css`
    margin-bottom: ${theme.spacing(2)};
    background: ${colors.transparent};
  `,
  popoverSortAndFilter: {
    closeBtn: css`
      cursor: pointer;
    `,

    itemButton: {
      icon: (iconSize: string) => css`
        svg {
          color: ${colors.gray.gray100};
          width: ${iconSize};
          height: ${iconSize};
        }
      `,
      label: (fontSize: string) => css`
        font-size: ${fontSize};
      `,
      root: css`
        justify-content: flex-start;
        align-items: center;
        padding: ${theme.spacing(4)} ${theme.spacing(4)};
        font-weight: ${typographyFontWeight.regular};
        border-radius: 8px;
        border: 1px solid ${colors.gray.gray10};
        gap: ${theme.spacing(2)};
      `,
    },
    popoverChildren: css`
      width: 25rem;
    `,

    popoverChildrenContent: css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: ${theme.spacing(4)};
    `,

    popoverChildrenContentCheckboxes: css`
      display: flex;
    `,
    popoverChildrenContentSortBy: css`
      padding: ${theme.spacing(4)};
    `,
    popoverChildrenContentSortByRadio: css`
      display: flex;
      flex-direction: column;
    `,
    popoverChildrenHeader: css`
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.gray.gray5};
      padding: ${theme.spacing(4)};
    `,
    sortByClear: css`
      color: ${colors.gray.gray40};
      cursor: pointer;
      margin-top: ${theme.spacing(2)};
    `,
  },
  root: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
  `,
  rootInitialized: css`
    opacity: 1;
    cursor: pointer;
  `,
  tenantIcon: css`
    position: relative;
    width: fit-content;
    border-radius: 9999px;
    margin-bottom: ${theme.spacing(2)};

    &:hover ${'.' + roundIconDelete} {
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
      ${shadowClasses['4']};
      transition: 0.8s;
    }
  `,
  tenantName: css`
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  `,
  tenantNameContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  `,
  threeDotsHeader: css`
    white-space: nowrap;
    ${threeDots};
    width: 100%;
  `,
  threeDotsText: css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    ${threeDots};
  `,

  tile: css`
    height: 11.5rem;
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: ${shadows[16]};
    cursor: pointer;

    &:hover {
      ${'.' + actionsContainer} {
        opacity: 1;
        z-index: 1;
        transition: z-index ease-in-out 0s 0s, opacity ease-in-out 0.2s 0s;
      }
    }
  `,

  topContainer: css`
    flex: 0 0 auto;
    padding-bottom: ${theme.spacing(1)};
  `,
}
