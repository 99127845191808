import { ReactNode, useMemo } from 'react'
import { Link as LinkComponent, LinkProps } from 'react-router-dom'
import { getRouteUrl, RouteNames, generateRouteObjects } from '@dis/routing'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { useAtlas, useFolder, useTenantPersona } from '@dis/hooks'
import { Paragraph } from '@dis/components'
import { useAppSelector } from '@dis/redux'
import { selectSelectedJourneyName } from '@dis/redux/src/journeys/journeysSelectors'
import { selectSelectedTenantName } from '@dis/redux/src/tenants/tenantsSelectors'
import { styles } from './styles'

const routeObjects = generateRouteObjects()

type Props = Omit<LinkProps, 'to'> & {
  icon?: ReactNode
  routeKey: RouteNames
  routeToKey?: RouteNames
}

export const Link = ({ routeKey, routeToKey, icon }: Props) => {
  const { t } = useTranslation()

  const journeyTitle = useAppSelector(selectSelectedJourneyName)
  const tenantName = useAppSelector(selectSelectedTenantName)

  const { subscribeData: atlasName } = useAtlas()
  const { subscribeData: folderName } = useFolder({ autoRefetch: { change: true } })
  const { persona } = useTenantPersona({
    semaphore: routeKey === 'persona',
  })

  const texts = useMemo(() => {
    const titles: Record<string, string | undefined> = {
      atlas: atlasName?.name,
      folder: folderName?.name,
      journey: journeyTitle,
      journeyFolder: journeyTitle,
      persona: persona?.info?.name,
      recycleBinJourney: journeyTitle,
      tenant: tenantName,
    }

    const title = titles[routeKey] || t(routeObjects[routeKey].title)

    const screensWithIconAndText: Record<string, string> = {
      atlas: title,
      folder: title,
      globalTemplate: title,
      journey: title,
      journeyFolder: title,
      localTemplate: title,
      newJourney: t(tKeys.routes.newJourney),
      newJourneyAtlas: t(tKeys.routes.newJourneyAtlas),
      newJourneyFolder: t(tKeys.routes.newJourneyFolder),
      persona: title,
      personas: t(tKeys.applicationMenu.personasManagement),
      recycleBinJourney: title,
      templateManagement: title,
      tenant: title,
    }

    let textForIcon = null
    const screenName = Object.keys(screensWithIconAndText)
    if (screenName.includes(routeKey)) {
      textForIcon = screensWithIconAndText[routeKey]
    }

    return { textForIcon, title }
  }, [
    atlasName?.name,
    folderName?.name,
    journeyTitle,
    persona?.info?.name,
    routeKey,
    t,
    tenantName,
  ])

  if (!routeToKey) return null
  const to = getRouteUrl(routeToKey)

  return (
    <LinkComponent to={to} className={styles.link}>
      <div className={styles.stack}>
        <div data-testid="breadcrumb-link">
          <Paragraph variant="small">{icon || texts.title}</Paragraph>
        </div>
        <Paragraph variant="small" className={styles.text}>
          {texts.textForIcon}
        </Paragraph>
      </div>
    </LinkComponent>
  )
}
