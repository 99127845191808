import React, { Dispatch, useCallback, useMemo } from 'react'
import {
  PopoverFilter as PopoverFilterComponent,
  PopoverFilterProps as PopoverFilterComponentProps,
} from '@dis/components'
import { tKeys } from '@dis/languages'
import { JourneyListFilter, JourneyTemplateFilter } from '@dis/types/src/JourneyTypes'
import { ChannelsFilter } from '@dis/types/src/ChannelTypes'
import { TemplatesFilter } from '@dis/types/src/TemplateTypes'
import { CommonFilterType, PopoverFilterCheckbox } from '@dis/types/src/PopoverFilterSortTypes'

export type PopoverFilterProps<F extends CommonFilterType> = Pick<
  PopoverFilterComponentProps,
  'buttonClassname' | 'popoverClassname'
> & {
  filters: F
  setFilters?: Dispatch<React.SetStateAction<F>>
  title: string | Array<string>
  type:
    | 'listOfJourney'
    | 'templates'
    | 'examples'
    | 'channels'
    | 'newJourneyTemplates'
    | 'newJourneyExamples'
}

type Checkboxes<F extends CommonFilterType> = Array<
  Omit<PopoverFilterCheckbox, 'attr'> & {
    attr: keyof F
  }
>

export const PopoverFilter = <F extends CommonFilterType>({
  filters,
  setFilters,
  title,
  type,
  ...rest
}: PopoverFilterProps<F>) => {
  const enhancedFilters = useMemo((): Record<string, boolean>[] => {
    if (Array.isArray(filters)) {
      return filters
    } else {
      return [filters]
    }
  }, [filters])

  const titles = useMemo((): string[] => {
    if (Array.isArray(title)) {
      return title
    } else {
      return [title]
    }
  }, [title])

  const handleApplyFilters = useCallback<
    NonNullable<PopoverFilterComponentProps['onApplyFilters']>
  >(
    (arrayOfCheckboxes) => {
      const updatedFilters = [...enhancedFilters]

      arrayOfCheckboxes.forEach((checkboxes, sIndex) => {
        checkboxes.forEach(({ checked, attr }) => {
          updatedFilters[sIndex] = {
            ...updatedFilters[sIndex],
            [attr as keyof CommonFilterType]: !!checked,
          }
        })
      })

      if (Array.isArray(filters)) {
        setFilters?.(updatedFilters as F)
      } else {
        setFilters?.(updatedFilters[0] as F)
      }
    },
    [filters, setFilters, enhancedFilters],
  )

  const checkboxes = useMemo((): PopoverFilterComponentProps['checkboxes'] => {
    if (type === 'examples') {
      return [
        [
          {
            attr: 'visible',
            checked: enhancedFilters[0]['visible'],
            labelTkey: tKeys.templateManagement.visible,
          },
          {
            attr: 'invisible',
            checked: enhancedFilters[0]['invisible'],
            labelTkey: tKeys.templateManagement.hidden,
          },
        ],

        Object.entries(enhancedFilters[1])
          .map(
            ([key, value]): PopoverFilterCheckbox => ({
              attr: key,
              checked: value,
              label: key,
            }),
          )
          .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase() || '') || 0),
      ]
    } else {
      let ret: Checkboxes<Record<string, boolean>> = []

      switch (type) {
        case 'listOfJourney': {
          const boxes: Checkboxes<JourneyListFilter> = [
            { attr: 'inProgress', labelTkey: tKeys.listOfJourneys.inProgress },
            { attr: 'published', labelTkey: tKeys.listOfJourneys.published },
          ]

          ret = boxes
          break
        }

        case 'templates': {
          const boxes: Checkboxes<JourneyTemplateFilter> = [
            { attr: 'visible', labelTkey: tKeys.templateManagement.visible },
            { attr: 'invisible', labelTkey: tKeys.templateManagement.hidden },
          ]

          ret = boxes
          break
        }

        case 'channels': {
          const boxes: Checkboxes<ChannelsFilter> = [
            { attr: 'visible', labelTkey: tKeys.channelsManagement.visible },
            { attr: 'hidden', labelTkey: tKeys.channelsManagement.hidden },
          ]

          ret = boxes
          break
        }

        case 'newJourneyTemplates': {
          const boxes: Checkboxes<TemplatesFilter> = [
            {
              attr: 'localTemplate',
              labelTkey: tKeys.journeys.new.templates.local,
            },
            {
              attr: 'globalTemplate',
              labelTkey: tKeys.journeys.new.templates.global,
            },
          ]

          ret = boxes
          break
        }

        case 'newJourneyExamples': {
          ret = Object.entries(enhancedFilters[0])
            .map(
              ([key, value]): PopoverFilterCheckbox => ({
                attr: key,
                checked: value,
                label: key,
              }),
            )
            .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase() || '') || 0)
          break
        }

        default: {
          console.error(`PopoverFilter: Filter for type "${type} not implemented!"`)
        }
      }

      return [
        ret?.map((checkbox) => ({
          ...checkbox,
          checked: !!enhancedFilters[0][checkbox.attr],
        })),
      ]
    }
  }, [enhancedFilters, type])

  return (
    <PopoverFilterComponent
      {...rest}
      checkboxes={checkboxes}
      titles={titles}
      onApplyFilters={handleApplyFilters}
    />
  )
}
