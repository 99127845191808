// Color palette specified by business - could be found in figma (https://www.figma.com/file/fcurJwqBrHvPv9t47UCx96/Discovery-KPMG?node-id=37-9496&t=PIFEz6YlJzfsW7p1-0)
// TODO: Complete palette when design ready
const businessPalette = {
  background: {
    white: '#FFFFFF',
    whiteBlue: '#FAFCFE',
  },
  buttons: {
    blueActive: '#00ABE4',
    greenActive: '#1C831A',
    greenDisabled: '#B4D7B4',
    greenHover: '#4EAA4D',
    redActive: '#C21A1C',
    redDisabled: '#F0B3B4',
    redHover: '#EE4A4D',
  },
  gray: {
    gray0: '#FFFFFF',
    gray10: '#E5E5E5',
    gray100: '#000000',
    gray20: '#CCCCCC',
    gray3: '#F7F7F7',
    gray40: '#999999',
    gray5: '#F2F2F2',
    gray60: '#666666',
    gray80: '#333333',
  },
  kpmg: {
    DarkGreen: '#63EBDA',
    blue: '#76D2FF',
    cobalt: '#1E49E2',
    cobaltBlue: '#5776EA',
    cobaltLight: '#EDF0FA',
    darkBlue: '#0C233C',
    darkBlueLight: '#495A6C',
    darkPink: '#AB0D82',
    darkPurple: '#510DBC',
    green: '#00C0AE',
    kpmgBlue: '#00338D',
    kpmgBlueLight: '#3E65AA',
    lightBlue: '#ACEAFF',
    lightGreen: '#63EBDA',
    lightPink: '#FE67B7',
    lightPurple: '#B497FF',
    negative: '#7552AC',
    pacificBlue: '#00B8F5',
    pink: '#FD349C',
    positive: '#C0AFF3',
    purple: '#7213EA',
  },
  semantic: {
    green: '#269924',
    greenLight: '#A0CE9F',
    greenSoft: '#E1FAE1',
    orange: '#F1C44D',
    orangeLight: '#F9E7B8',
    red: '#ED2124',
    redLight: '#F69091',
  },
  text: '#25262A',
} as const

// connected capabilities colors, might delete later
const connectedCapabilitiesColors = {
  '#2E711A': '#2E711A',
  '#367BC4': '#367BC4',
  '#3AB2B2': '#3AB2B2',
  '#673683': '#673683',
  '#6798cb': '#6798cb',
  '#69BE4F': '#69BE4F',
  '#6ac8c8': '#6ac8c8',
  '#7ccf63': '#7ccf63',
  '#86488f': '#86488f',
  '#86498F': '#86498F',
  '#8ab57d': '#8ab57d',
  '#9B5B1E': '#9B5B1E',
  '#C54A6B': '#C54A6B',
  '#a374be': '#a374be',
  '#b96ec4': '#b96ec4',
  '#be9874': '#be9874',
  '#cf6380': '#cf6380',
} as const

const scrollbarColors = {
  '#555': '#555',
  '#ddd': '#ddd',
} as const

/**
 * https://mui.com/material-ui/customization/color/
 */
export const colors = {
  // emotional curve brown color
  '#AD7F13': '#AD7F13',
  // transparent color
  transparent: 'transparent',
  // pallets
  ...scrollbarColors,
  ...connectedCapabilitiesColors,
  ...businessPalette,
  shadow: '0 0 30px #cccccc',
} as const
