import { PRIORITY, STATUS } from '@dis/modules/src/backlog/constants'
import { CheckboxForm } from './types'

export const convertBacklogFilterToNumbers = (filterObj: CheckboxForm) => {
  return {
    priority: boolsToNumbers(filterObj.priority, PRIORITY),
    status: boolsToNumbers(filterObj.status, STATUS),
  }
}

const boolsToNumbers = <T extends string | number | symbol>(
  object: Record<T, boolean>,
  numbersDic: Record<T, number>,
) =>
  Object.keys(object)
    .filter((key) => object[key as T])
    .map((key) => numbersDic[key as T])
