import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  statusContainer: css`
    display: flex;
    margin-inline-end: ${theme.spacing(3)};
    align-items: center;
    color: ${colors.gray.gray5};
  `,
}
