import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination, Screen, SwitchButtonProps } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useNavTo, useTenantList } from '@dis/hooks'
import { useTranslation } from 'react-i18next'
import { TenantListSortType } from '@dis/types/src/PopoverFilterSortTypes'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectTenantDisplayType } from '@dis/redux/src/tenants/tenantsSelectors'
import { useHandleOpen } from '@dis/hooks/src/useHandleOpen'
import { CreateOrEditTenantModal } from '@dis/modules/src/tenant/CreateOrEditTenantModal'
import {
  selectCanCreateNewTenant,
  selectUserRole,
  selectUserTenantId,
} from '@dis/redux/src/security/selectors'
import { DeleteTenantModal } from '@dis/modules/src/tenant/DeleteTenantModal'
import { applySortAndFilter } from '@dis/modules/src/PopoverSortFilter/utils'
import { TenantList } from '@dis/types/src/TenantTypes'
import { usePagination } from '@dis/hooks/src/usePagination'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { useTenantDelete, useTenantEdit } from '@dis/modules/src/tenant/hooks'
import { ScreenActionsPanel } from '@dis/modules/src/ScreenActionsPanel/ScreenActionsPanel'
import { ViewEnum } from '@dis/types/src/GeneralTypes'
import { TableView } from './TableView'
import { CardView } from './CardView'

export const Tenants = () => {
  const [searchValue, setSearchValue] = useState('')
  const [sortBy, setSortBy] = useState<TenantListSortType>()

  const userRole = useAppSelector(selectUserRole)
  const userTenantId = useAppSelector(selectUserTenantId)
  const canCreateNewTenant = useAppSelector(selectCanCreateNewTenant)
  const tenantDisplayType = useAppSelector(selectTenantDisplayType)
  const locale = useAppSelector(selectLanguage)

  const { t } = useTranslation()

  const { navigate } = useNavTo()

  const { open, handleOpen, handleClose } = useHandleOpen()

  const { tenantList, loading } = useTenantList({
    loadingSpinner: true,
  })

  const setMode = useCallback<NonNullable<SwitchButtonProps<ViewEnum>['onChange']>>((viewEnum) => {
    dispatchedActions.tenants.setTenantDisplayType(viewEnum)
  }, [])

  const { hideEditModal, showEditModal, tenantChange, openEditTenant } = useTenantEdit()
  const { handleCloseDeleteTenant, openDeleteTenant, openDeleteTenantModal, tenantDelete } =
    useTenantDelete()

  const tenants = useMemo(() => {
    const dataToSort = tenantList || []

    return applySortAndFilter<TenantList['tenants']>({
      data: dataToSort,
      locale,
      searchValue,
      sortBy,
    })
  }, [tenantList, sortBy, locale, searchValue])

  const { currentPage, maxPage, handlePageChange, currentData } = usePagination(
    tenants,
    tenantDisplayType === ViewEnum.Table ? 10 : 6,
  )

  useEffect(() => {
    if (userRole === 'kpmguser') {
      setMode(ViewEnum.Card)
    }
  }, [userRole, setMode])

  useEffect(() => {
    if (userRole !== 'kpmgadmin' && userRole !== 'kpmguser' && userTenantId) {
      dispatchedActions.tenants.setSelectedTenantId(userTenantId)
      navigate('tenant', { tenantId: userTenantId })
    } else {
      dispatchedActions.tenants.setSelectedTenantId()
    }
  }, [userRole, userTenantId, navigate])

  if (loading || !(userRole === 'kpmgadmin' || userRole === 'kpmguser')) {
    return <></>
  }

  return (
    <Screen width="normal" title={t(tKeys.tenants.allTenants)}>
      <ScreenActionsPanel
        actionButtonText={t(tKeys.tenants.addNewTenant)}
        actionButtonIconVariant="add"
        hasActionButton={canCreateNewTenant}
        hasSort
        hasViewSwitch
        onActionButtonClick={handleOpen}
        searchPlaceholder={t(tKeys.tenants.searchTenant)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSortBy={setSortBy}
        setViewSwitch={setMode}
        sortBy={sortBy}
        sortType="tenants"
        viewSwitchValue={tenantDisplayType}
      />

      {tenantDisplayType === ViewEnum.Table ? (
        <TableView
          tenants={currentData}
          showEditModal={showEditModal}
          openDeleteTenantModal={openDeleteTenantModal}
        />
      ) : (
        <CardView
          tenants={currentData}
          showEditModal={showEditModal}
          openDeleteTenantModal={openDeleteTenantModal}
        />
      )}

      <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />

      {canCreateNewTenant && (
        <CreateOrEditTenantModal isOpen={open} onClose={handleClose} mode="create" />
      )}

      <DeleteTenantModal
        tenantId={tenantDelete?.id}
        name={tenantDelete?.name}
        open={openDeleteTenant}
        handleClose={handleCloseDeleteTenant}
        isTemplate={tenantDelete?.istemplate}
      />

      <CreateOrEditTenantModal
        isOpen={!!tenantChange && openEditTenant}
        onClose={hideEditModal}
        mode="edit"
        tenantId={tenantChange?.id}
        name={tenantChange?.name}
        description={tenantChange?.description}
      />
    </Screen>
  )
}
