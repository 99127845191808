import { ReactNode, FC } from 'react'
import { Grid, ButtonProps } from '@mui/material'
import { Header, TextIconButton, TextIconButtonProps } from '@dis/components'
import { PopoverSort } from '@dis/modules/src/PopoverSortFilter/PopoverSort'
import { CreatedUpdatedTitleSortType } from '@dis/types/src/PopoverFilterSortTypes'
import { colors, cx } from '@dis/styles'
import { styles } from './styles'

type IconVariant = NonNullable<TextIconButtonProps['iconVariant']>

type ButtonsProps = {
  disabled?: boolean
  iconVariant: IconVariant
  onClick: VoidFunction | (() => Promise<void>)
  text: string
}

export interface ScreenProps {
  button?: ButtonsProps
  children: ReactNode
  isSort?: boolean
  secondButton?: ButtonsProps
  setSortBy?: React.Dispatch<React.SetStateAction<CreatedUpdatedTitleSortType | undefined>>
  sortBy?: CreatedUpdatedTitleSortType
  title?: string
  width?: 'auto' | 'normal'
}

const iconVariants: Partial<Record<IconVariant, ButtonProps['variant']>> = {
  addRound: 'text',
  edit: 'outlined',
}

export const Screen: FC<ScreenProps> = ({
  children,
  title,
  button,
  secondButton,
  sortBy,
  setSortBy = () => {},
  isSort,
  width = 'normal',
}) => {
  return (
    <div className={cx(styles.container, { [styles.normalWidth]: width === 'normal' })}>
      {title && (
        <Grid container justifyContent="space-between" pb={8}>
          <Header variant="h1" color={colors.text}>
            {title}
          </Header>
          <div
            className={cx(styles.buttonsContainer, {
              [styles.secondaryBtn]: !!secondButton,
            })}>
            {isSort && <PopoverSort sortBy={sortBy} setSortBy={setSortBy} />}
            {secondButton && (
              <TextIconButton
                variant={iconVariants[secondButton.iconVariant] || 'contained'}
                color="secondary"
                iconVariant={secondButton.iconVariant}
                onClick={secondButton.onClick}
                disabled={secondButton.disabled}
                text={secondButton.text}
              />
            )}
            {button && (
              <TextIconButton
                variant={iconVariants[button.iconVariant] || 'contained'}
                color="primary"
                iconVariant={button.iconVariant}
                onClick={button.onClick}
                disabled={button.disabled}
                text={button.text}
              />
            )}
          </div>
        </Grid>
      )}
      {children}
    </div>
  )
}
