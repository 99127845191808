import Button from '@mui/material/Button'
import { Paragraph, Tile } from '@dis/components'
import { colors } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { dateHandler } from '@dis/utils'
import { t, tKeys } from '@dis/languages'
import { Item } from '@dis/components/src/PopoverWithAnchor/PopoverWithAnchor'
import { Tenant } from '@dis/types/src/TenantTypes'
import { UserRole } from '@dis/types/src/UsersAndRoles'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { styles } from './styles'
import { useTenantPage } from './hooks'

type Props = {
  menu?: Array<Item>
  tenantData: Tenant
}

export const TenantCard = ({ menu, tenantData }: Props) => {
  const { description, created, name } = tenantData

  const { navigateToLandingPage } = useTenantPage()

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const actualUserRole = useAppSelector(selectUserRole)

  return (
    <Tile
      horizontalAlign="left"
      size="large"
      className={styles.tile}
      menu={menu}
      menuOnLeft
      onClick={navigateToLandingPage(tenantData)}>
      <div className={styles.body}>
        <div className={styles.topContainer}>
          <Paragraph variant="medium" fontWeight="bold" className={styles.threeDotsHeader}>
            {name}
          </Paragraph>
        </div>
        {actualUserRole === UserRole.KpmgAdmin && (
          <div className={styles.descContainer}>
            {description && <Paragraph className={styles.threeDotsText}>{description}</Paragraph>}
          </div>
        )}
        <div className={styles.createdContainer}>
          <Paragraph color={colors.gray.gray40} variant="small">
            {created && t(tKeys.templateManagement.created)}: {dateHandler(created, isRtl)}
          </Paragraph>
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <Button variant="contained" color="primary">
          {t(tKeys.tenants.select)}
        </Button>
      </div>
    </Tile>
  )
}
