import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { ModalDialogProps } from '@dis/types/src/ModalDialogTypes'
import { cx } from '@dis/styles'
import { DISALLOW_DND } from '@dis/constants'
import { FileInput } from '@dis/modules/src/UploadImage/FileInput'
import { styles } from './ModalStyles'
import { Paragraph } from '../Typography/Paragraph'

type ModalFooterProps = Pick<
  ModalDialogProps,
  | 'alignButtons'
  | 'icon'
  | 'isDisabled'
  | 'isSecondaryBtnInput'
  | 'onInputClick'
  | 'onPrimaryBtnClick'
  | 'onSecondaryBtnClick'
  | 'ownFooter'
  | 'primaryBtnFullWidth'
  | 'primaryBtnText'
  | 'secondaryBtnText'
  | 'textInFooter'
>

const alignButtonsMap: Record<NonNullable<ModalDialogProps['alignButtons']>, string> = {
  between: 'space-between',
  center: 'center',
  end: 'flex-end',
  start: 'flex-start',
} as const

export const ModalFooter = ({
  alignButtons = 'end',
  icon,
  isDisabled,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onInputClick = () => {},
  ownFooter,
  primaryBtnFullWidth,
  primaryBtnText,
  secondaryBtnText,
  isSecondaryBtnInput,
  textInFooter,
}: ModalFooterProps) => {
  const { t } = useTranslation()

  if (!ownFooter && !primaryBtnText && !secondaryBtnText) {
    return <></>
  }

  return (
    <div
      id="components-modal-footer"
      className={cx(
        styles.modalFooter.root,
        styles.modalFooter.justifyContent(alignButtonsMap[alignButtons]),
        DISALLOW_DND,
      )}>
      {ownFooter || (
        <>
          {!!textInFooter && (
            <div className={styles.modalFooter.textContainer}>
              <Paragraph>{textInFooter}</Paragraph>
            </div>
          )}
          {!!secondaryBtnText && (
            <Button
              className={styles.modalFooter.button}
              color="secondary"
              variant="contained"
              size="medium"
              component={isSecondaryBtnInput ? 'label' : 'button'}
              onClick={isSecondaryBtnInput ? undefined : onSecondaryBtnClick}
              disabled={isDisabled}>
              {secondaryBtnText}
              {isSecondaryBtnInput && <FileInput onChange={onInputClick} />}
            </Button>
          )}
          {!!primaryBtnText && (
            <Button
              className={styles.modalFooter.button}
              color="primary"
              disabled={isDisabled}
              fullWidth={primaryBtnFullWidth}
              onClick={onPrimaryBtnClick}
              startIcon={icon}
              variant="contained">
              {primaryBtnText || t(tKeys.common.close)}
            </Button>
          )}
        </>
      )}
    </div>
  )
}
