import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useTenant, useTypedParams } from '@dis/hooks'
import { dispatchedActions } from '@dis/redux'

export const Authenticated = () => {
  const { tenantId } = useTypedParams()

  // Init tenant data and save to redux
  useTenant()

  useEffect(() => {
    dispatchedActions.tenants.setSelectedTenantId(tenantId)

    // Delete tenant data from redux
    if (!tenantId) {
      dispatchedActions.tenants.setTenantData(undefined)
    }

    // We assume the webpage is reload if the user is logged out but still it's more secure to delete the redux manually
    return () => {
      dispatchedActions.backlog.init()
      dispatchedActions.journeys.init()
      dispatchedActions.personas.init()
      dispatchedActions.search.init()
      dispatchedActions.tenants.init()
      dispatchedActions.userManagement.init()

      // TODO: clear BE API cache
    }
  }, [tenantId])

  return <Outlet />
}
