import { tKeys } from '@dis/languages'
import { LayerModelType, LockedLayers, TargetType } from '@dis/modules/src/journey/grid/types'
import { colors, theme } from '@dis/styles'

// TODO: Move structure indexes to Enum
// journey grid header structure indexes
export const PHASE_INDEX = 0
export const SUBPHASE_INDEX = 1
export const TOUCHPOINT_INDEX = 2

export const HEADER_SIZE = 82
export const GRID_MARGIN_START = 30
export const GRID_MARGIN_TOP = 30
export const FOOTER_HEIGHT = '7.2rem'
export const BACKLOG_STATUS_TERMINATED = 40
// journey grid utils keywords
export const BOX_BASE_WIDTH = 325
export const SIDEBAR_WIDTH = 240
export const LAYER_DIVIDER_WIDTH = 1
export const INLINE_PROCESSES_HEIGHT = 500
export const COLLAPSED_STRUCTURE_WIDTH = 30
export const PHASE_AND_SUBPHASE_HEIGHT = 60
export const PHASE_AND_SUBPHASE_OFFSET = PHASE_AND_SUBPHASE_HEIGHT + 1 // +1 for bottom border to be displayed
export const OPENED_STRUCTURE_WIDTH = 300
export const BEFORE_KEY = 'before'
export const BEHIND_KEY = 'behind'
export const WS_PHASE_PATH_PREFIX = 'phases'
export const WS_SUBPHASE_PATH_PREFIX = 'subphases'
export const WS_TOUCHPOINT_PATH_PREFIX = 'touchpoints'
export const WS_LAYER_PATH_PREFIX = 'layers'
export const WS_PERSONA_PATH_PREFIX = 'personas'
export const WS_INFO_PATH_PREFIX = 'info'
export const SUBPHASE_DEFAULT_COLOR = colors.kpmg.pacificBlue
export const SUBPHASE_COLORS = [
  colors.kpmg.green,
  colors.kpmg.cobalt,
  colors.kpmg.kpmgBlue,
  colors.kpmg.darkBlue,
  colors.kpmg.purple,
  colors.kpmg.pink,
]

export const SUBPHASE_TOUCHPOINT_WIDTH = 3

export const indexWsPhaseMap: string[] = []
indexWsPhaseMap[PHASE_INDEX] = WS_PHASE_PATH_PREFIX
indexWsPhaseMap[SUBPHASE_INDEX] = WS_SUBPHASE_PATH_PREFIX
indexWsPhaseMap[TOUCHPOINT_INDEX] = WS_TOUCHPOINT_PATH_PREFIX

export const DELIBERATELY_HIDDEN_LAYERS = [LayerModelType.DesignPrinciples]
export const DESIGN_PRINCIPLES_V2_MAX_ITEMS = 4

export const stickyOffsets = [
  0,
  PHASE_AND_SUBPHASE_OFFSET,
  PHASE_AND_SUBPHASE_OFFSET + PHASE_AND_SUBPHASE_OFFSET,
]

export const defaultLockedLayers: LockedLayers = {
  [TargetType.Phase]: true,
  [TargetType.Subphase]: true,
  [TargetType.Touchpoint]: true,
}

export const indexToTargetType: Record<number, TargetType> = {
  [PHASE_INDEX]: TargetType.Phase,
  [SUBPHASE_INDEX]: TargetType.Subphase,
  [TOUCHPOINT_INDEX]: TargetType.Touchpoint,
}

/**
 * Type of layer determines how many cells a layer produces
 */
export const LAYER_ITEM_TYPE = {
  /** Include phase, subphase and touchpoint */
  All: {
    [TargetType.Phase]: [],
    [TargetType.Subphase]: [],
    [TargetType.Touchpoint]: [],
  },

  /** Include subphase and touchpoint */
  ExcludePhase: {
    [TargetType.Subphase]: [],
    [TargetType.Touchpoint]: [],
  },

  /** Include phase and subphase */
  ExcludeTouchpoint: {
    [TargetType.Phase]: [],
    [TargetType.Subphase]: [],
  },

  /** Empty */
  None: {},

  /** Include only touchpoint */
  OnlyTouchpoints: {
    [TargetType.Touchpoint]: [],
  },
}

export const tooltipTexts = {
  [TargetType.Phase]: tKeys.journeys.phase,
  [TargetType.Subphase]: tKeys.journeys.subPhase,
  [TargetType.Touchpoint]: tKeys.journeys.touchpoint,
}

export const LOADING_OVERLAY_ZINDEX = theme.zIndex.appBar - 2
export const JOURNEY_MODAL_DIALOG_ZINDEX = LOADING_OVERLAY_ZINDEX - 1
