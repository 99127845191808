export enum UserRole {
  KpmgAdmin = 'kpmgadmin',
  KpmgUser = 'kpmguser',
  PowerUser = 'poweruser',
  TenantAdmin = 'tenantadmin',
  User = 'user',
}

export enum UserTypeEnum {
  KpmgUser = 'kpmg',
  TenantUser = 'tenant',
}

export type UserType = `${UserTypeEnum}`

/**
 * WS subscribe response
 */
export type UserGroups = {
  groups: Array<{
    id: number
    name?: string
    tenantid: number
  }>
  users: Array<{
    availableTenants: []
    groups: Array<number>
    id: number
    locale?: string
    mail?: string
    name?: string
    oid?: string
    role?: string
  }>
}

/**
 * WS subscribe response
 */
export type KpmgUserTenants = {
  kpmgUsers: Array<{
    id: number
    mail?: string
    name?: string
    oid?: string
    role?: string
    tenants: Array<number>
  }>
  tenants: Array<{
    id: number
    name?: string
  }>
}
