import { CircularProgress } from '@mui/material'
import { cx } from '@emotion/css'
import { t, tKeys } from '@dis/languages'
import { Paragraph } from '@dis/components'
import { styles } from './styles'

export const Loader = ({ title = t(tKeys.exportImport.exportModal) }: { title?: string }) => {
  return (
    <div className={cx(styles.loader.root, styles.loader.modalBg)}>
      <div className={styles.loader.modalBox}>
        <CircularProgress />
        <Paragraph className={styles.loader.modalText}>{title}</Paragraph>
      </div>
    </div>
  )
}
