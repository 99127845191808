import { Fragment, useCallback } from 'react'
import { AppBar, Hidden, Link, Toolbar, Grid } from '@mui/material'
import { ApplicationMenu, Breadcrumb } from '@dis/modules'
import { PublicationStatus } from '@dis/modules/src/publication-status/PublicationStatus'
import { RevisionControl } from '@dis/modules/src/revision-control/RevisionControl'
import ResultsSearchBar from '@dis/modules/src/search/ResultsSearchBar'
import { useAppSelector } from '@dis/redux'
import { cx } from '@emotion/css'
import { useNavTo } from '@dis/hooks'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import KpmgLogo from '@dis/assets/src/icons/LogoKpmg.svg'
import MapExIcon from '@dis/assets/src/icons/MapExIcon.svg'
import { SvgImage } from '@dis/components'
import { useRouteMatch } from '@dis/hooks/src/useRouteMatch'
import { selectIsAuthenticated } from '@dis/redux/src/security/selectors'
import {
  selectHasCentralModalLoaderHighPriority,
  selectIsCentralModalLoaderVisible,
} from '@dis/redux/src/centralModalLoader/selectors'
import { selectLogoTenantMapEx } from '@dis/redux/src/tenants/tenantsSelectors'
import { styles } from './styles'

export const Header = () => {
  const isDiscovery = useAppSelector(selectLogoTenantMapEx)
  const { navigate } = useNavTo()

  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isCentralModalLoaderVisible = useAppSelector(selectIsCentralModalLoaderVisible)
  const hasCentralModalLoaderHighPriority = useAppSelector(selectHasCentralModalLoaderHighPriority)
  const isRtl = useAppSelector(selectIsLanguageRtl)

  const {
    isPersona,
    isJourney,
    isJourneyFolders,
    isRecycleBinJourney,
    isSearch,
    isTemplatePreview,
  } = useRouteMatch()

  const showRevisionControl = isJourney || isPersona || isJourneyFolders
  const showPublicationStatus = isJourney || isRecycleBinJourney || isJourneyFolders
  const showSearchBar = isSearch

  const icon = isDiscovery ? KpmgLogo : MapExIcon

  const goToRoot = useCallback(() => {
    navigate('tenants')
  }, [navigate])

  return (
    <AppBar
      sx={{
        px: {
          lg: 10,
          md: 2,
          sm: 1,
        },
      }}
      className={cx(styles.appBar, {
        [styles.higherZindex]: isCentralModalLoaderVisible && !hasCentralModalLoaderHighPriority,
      })}>
      <Toolbar className={styles.toolbar}>
        <Grid item className={cx(styles.logoContainer, { [styles.logoContainerAr]: isRtl })}>
          <Link onClick={goToRoot} sx={{ cursor: 'pointer', mx: 3 }}>
            <Hidden lgDown>
              <SvgImage src={icon} />
            </Hidden>
            <Hidden lgUp mdDown>
              <SvgImage src={icon} width="75px" height="28px" />
            </Hidden>
            <Hidden mdUp>
              <SvgImage src={icon} width="56px" height="22px" />
            </Hidden>
          </Link>
        </Grid>

        {isAuthenticated && !isTemplatePreview && (
          <Fragment>
            <Grid item className={styles.breadcrumbContainer}>
              <Breadcrumb />
            </Grid>
            <Grid
              item
              className={cx(styles.menuContainer, {
                [styles.publicationStatus]: isRtl,
              })}>
              {showPublicationStatus && <PublicationStatus />}
            </Grid>
            <Grid
              item
              className={cx(styles.menuContainer, {
                [styles.revisionControl]: isRtl,
              })}>
              {showRevisionControl && (
                <RevisionControl isPersona={isPersona} isJourney={isJourney || isJourneyFolders} />
              )}
            </Grid>
            <Grid
              item
              className={cx(styles.menuContainer, {
                [styles.searchBar]: isRtl,
              })}>
              {showSearchBar && (
                <div className={styles.resultsSearchBarWrapper}>
                  <ResultsSearchBar />
                </div>
              )}
            </Grid>
          </Fragment>
        )}

        {!isTemplatePreview && (
          <Grid item className={cx(styles.menuContainer, { [styles.menuContainerAr]: isRtl })}>
            <ApplicationMenu />
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  )
}
