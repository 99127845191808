import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import { routeFullPaths } from '@dis/routing'
import { useMatch } from 'react-router-dom'
import { LoadingInfoType } from '@dis/modules/src/ApplicationMenu/ApplicationMenu'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { exportDocument } from '@dis/api'

export const useExportPDF = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const isPersona = !!useMatch(routeFullPaths?.persona || '')
  // const isJourney = !!useMatch(routeFullPaths?.persona || '')

  const tenantId = useAppSelector(selectSelectedTenantId)
  const localeId = useAppSelector(selectLanguage)
  const personaId = useAppSelector(selectSelectedPersonaId)
  const journeyId = useAppSelector(selectSelectedJourneyId)

  const handleExportPDF = useCallback(async () => {
    let response

    if (isPersona) {
      if (personaId) {
        response = await exportDocument('personaPdf', {
          localeId,
          personaId: personaId,
          tenantId,
        })
      }
    } else {
      response = await exportDocument('journeyPdf', { journeyId, localeId, tenantId })
    }

    if (response) {
      setLoadingInfo({ isLoading: false, status: response?.status })
    }
  }, [isPersona, journeyId, localeId, personaId, setLoadingInfo, tenantId])

  return { handleExportPDF }
}
