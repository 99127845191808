import { css } from '@emotion/css'
import { theme, colors } from '@dis/styles'

const breadcrumbContainer = css`
  order: 4;
  flex: 1 1 auto;
  margin-top: ${theme.spacing(2)};
  margin-inline-end: auto;
`

export const styles = {
  appBar: css`
    box-shadow: none;
    display: flex;
    background-color: ${colors.kpmg.darkBlue};
    position: sticky;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: auto;
  `,
  breadcrumbContainer,
  higherZindex: css`
    z-index: ${theme.zIndex.modal - 1};
  `,
  logoContainer: css`
    order: 2;
    flex: 0 0 auto;
    margin-top: ${theme.spacing(2)};
  `,
  logoContainerAr: css`
    order: 6;
  `,
  menuContainer: css`
    align-items: center;
    display: flex;
    order: 6;
    flex: 0 0 auto;
    margin-top: ${theme.spacing(2)};
  `,
  menuContainerAr: css`
    order: 3;
  `,
  publicationStatus: css`
    order: 4;
    margin-left: ${theme.spacing(3)};
  `,
  resultsSearchBarWrapper: css`
    margin-right: ${theme.spacing(5)};
  `,
  revisionControl: css`
    order: 5;
  `,
  searchBar: css`
    order: 5;
    margin-inline-end: ${theme.spacing(10)};
    .MuiInputBase-root {
      padding-inline-end: ${theme.spacing(3.5)};
    }
  `,
  toolbar: css`
    flex-wrap: wrap;
    justify-content: space-between;

    & > *${'.' + breadcrumbContainer} + * {
      margin-inline-start: auto;
    }
  `,
}
