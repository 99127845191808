import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { tKeys } from '@dis/languages'
import { t } from 'i18next'
import { LoadingInfoType } from '@dis/modules/src/ApplicationMenu/ApplicationMenu'
import { importExcelToJourney } from '@dis/api'
import { selectLanguage } from '@dis/redux/src/user/selectors'

export const useImportToXlsx = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)
  const localeId = useAppSelector(selectLanguage)

  const handleImportXlsx = useCallback(() => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.style.position = 'absolute'
    fileInput.style.visibility = 'hidden'
    document.body.appendChild(fileInput)

    const handleFileInputChange = async (event: Event) => {
      const currentTarget = event.target as HTMLInputElement
      const selectedFile = currentTarget.files?.[0]
      if (selectedFile) {
        setLoadingInfo({ isLoading: true, translation: t(tKeys.exportImport.importModal) })
      } else if (!selectedFile) return
      const newXlsxFile = new File([selectedFile], 'xlsx;charset=utf-8')
      const xlsxFormData = new FormData()
      xlsxFormData.append('file', newXlsxFile)
      if (selectedJourneyId) xlsxFormData.append('documentid', selectedJourneyId?.toString())
      const importExcelData = await importExcelToJourney(xlsxFormData, selectedTenantId, localeId)
      if (importExcelData) {
        setLoadingInfo({
          fileName: selectedFile.name,
          isImport: true,
          isLoading: false,
          status: importExcelData.status,
          translation: undefined,
        })
      }
    }

    fileInput.addEventListener('change', handleFileInputChange)
    fileInput.click()
    fileInput.remove()
  }, [selectedJourneyId, selectedTenantId, setLoadingInfo, localeId])

  return { handleImportXlsx }
}
