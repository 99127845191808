import { css } from '@emotion/css'
import { colors, theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  buttonContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: ${theme.spacing(8)};
    gap: ${theme.spacing(3)};
  `,
  closeBtn: css`
    cursor: pointer;
  `,
  mainButton: {
    btnBorderColor: css`
      border-color: ${colors.kpmg.pacificBlue} !important;
    `,
    icon: (iconSize: string) => css`
      svg {
        color: ${colors.gray.gray100};
        width: ${iconSize};
        height: ${iconSize};
      }
    `,
    iconWithMargin: css`
      margin-inline-end: ${theme.spacing(2)};
    `,
    label: (fontSize: string, fontWeight: number) => css`
      font-size: ${fontSize};
      font-weight: ${fontWeight};
    `,
    root: css`
      height: 2.5rem;
      color: ${colors.gray.gray80};
      justify-content: flex-start;
      align-items: center;
      padding: ${theme.spacing(2.5)} ${theme.spacing(4)};
      font-weight: ${typographyFontWeight.regular};
      border-radius: 8px;
      border: 1px solid ${colors.gray.gray10};
      gap: ${theme.spacing(2)};
      &:hover {
        border-color: ${colors.kpmg.pacificBlue} !important;
      }
    `,
  },
  popoverChildren: css`
    width: 19rem;
    max-width: 100%;
  `,
  popoverChildrenContent: css`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: ${theme.spacing(5)};
  `,
  popoverChildrenHeader: css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.gray.gray5};
    padding: ${theme.spacing(4)};
  `,
}
