import { useCallback, useRef, useState } from 'react'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'
import { WsEnhancedResponseMessage, WsRevertMessage } from '@dis/types/src/wsModels'
import { Api } from '@dis/api'

type UseRevertProps = {
  onData?: (data: PersonaDetailType) => void
}

export const useRevert = (props?: UseRevertProps) => {
  const [data, setData] = useState<PersonaDetailType>()
  const [loading, setLoading] = useState(true)

  const onData = props?.onData
  const send = useRef<(message: WsRevertMessage) => void>()

  const onMessage = useCallback(
    (newData: WsEnhancedResponseMessage) => {
      setData(newData.data)
      setLoading(false)
      onData?.(newData.data)
    },
    [onData],
  )

  const sendWrapper = useCallback(
    (message: WsRevertMessage) => {
      Api.sendRevert(message, onMessage)
    },
    [onMessage],
  )

  send.current = sendWrapper

  return {
    data,
    loading,
    send: send.current,
  }
}
