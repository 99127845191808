import { RootState } from '@dis/redux'
import get from 'lodash/get'

export const selectUserOid = (state: RootState) => state.security.userOid

export const selectUserRole = (state: RootState) => state.security.userRole

export const selectUserTenantId = (state: RootState) => state.security.userTenantId

export const selectApplicationMenuPermission =
  (key: string) =>
  (state: RootState): Record<string, boolean> | boolean =>
    get(state.security.permissions.menuItems, key)

export const selectCanCreateNewTenant = (state: RootState) =>
  state.security.permissions.canCreateNewTenant

export const selectCanDeleteTenant = (state: RootState) =>
  state.security.permissions.canDeleteTenant

export const selectPermissions = (state: RootState) => state.security.permissions

export const selectIsFeatureConnectedCapabilitiesAllowed = (state: RootState) => {
  return state.security.feature.isConnectedCapabilitiesAllowed
}

export const selectIsArabicLanguageAllowed = (state: RootState) => {
  return state.security.feature.isArabicLanguageAllowed
}

export const selectIsAuthenticated = (state: RootState) => state.security.isAuthenticated

export const selectAccessTokenExpireTimestamp = (state: RootState) =>
  state.security.accessTokenExpireTimestamp
