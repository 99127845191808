import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRole } from '@dis/types/src/UsersAndRoles'
import { SecurityReducerState } from './types'

// Generating a fresh new init state prevents read-only errors when re-initializing some nested objects of the redux state
const getInitialState = (): SecurityReducerState => ({
  accessTokenExpireTimestamp: undefined,
  feature: {
    isArabicLanguageAllowed: false,
    isConnectedCapabilitiesAllowed: false,
  },
  isAuthenticated: undefined, // We need 3-state logic: true/false/undefined
  permissions: {
    canCreateNewAtlas: false,
    canCreateNewPersona: false,
    canCreateNewTenant: false,
    canDeleteAtlas: false,
    canDeletePersona: false,
    canDeleteTenant: false,
    canEditPersona: false,
    canImportCapabilities: false,
    menuItems: {
      capabilities: false,
      configuration: {
        capabilityManagement: false,
        channels: false,
        editTenant: false,
        exportTenantUsers: false,
        globalCapabilityManagement: false,
        globalTemplateManagement: false,
        globalUserManagement: false,
        limits: false,
        templateManagement: false,
        userManagement: false,
      },
      exportToPdf: false,
      exportToXlsx: false,
      exportXML: false,
      importToXlsx: false,
      language: {
        ar: true,
        cs: true,
        de: true,
        en: true,
        fr: true,
      },
      logout: true,
      logoutAllDevices: true,
      personas: false,
      recycleBin: false,
      search: false,
      sendEmail: false,
      shareJourney: false,
      tutorial: false,
      userStory: false,
    },
  },
  userOid: undefined,
  userRole: undefined,
  userTenantId: undefined,
})

// TODO: Add setAuthority, setUniqueId, setSelectedTenantId, etc..
export const securitySlice = createSlice({
  initialState: getInitialState(),
  name: 'security',
  reducers: {
    loginSuccess: (_state, _payload: PayloadAction<undefined>) => undefined,
    logoutAllDevices: (_state, _payload: PayloadAction<undefined>) => undefined,
    logoutThisDevice: (_state, _payload: PayloadAction<undefined>) => undefined,
    setAccessTokenExpireTimestamp: (
      state: SecurityReducerState,
      action: PayloadAction<number | undefined>,
    ) => {
      state.accessTokenExpireTimestamp = action.payload
    },
    setFeatureFlags: (state, { payload }: PayloadAction<SecurityReducerState['feature']>) => {
      state.feature = payload
    },
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.isAuthenticated = payload
    },
    setUserOid: (state: SecurityReducerState, action: PayloadAction<string | undefined>) => {
      state.userOid = action.payload
    },
    setUserRole: (
      state,
      {
        payload: { userTenantId, userRole },
      }: PayloadAction<{ userRole: UserRole | undefined; userTenantId: number | undefined }>,
    ) => {
      state.userRole = userRole
      state.userTenantId = userTenantId
      state.permissions = getInitialState().permissions

      // Same for all
      state.permissions.menuItems.personas = true
      state.permissions.menuItems.userStory = true
      state.permissions.menuItems.recycleBin = true
      state.permissions.menuItems.search = true
      state.permissions.menuItems.sendEmail = true
      state.permissions.menuItems.shareJourney = true
      state.permissions.menuItems.exportXML = true
      state.permissions.menuItems.capabilities = true
      state.permissions.menuItems.tutorial = true
      state.permissions.menuItems.exportToPdf = true
      state.permissions.menuItems.exportToXlsx = true
      state.permissions.menuItems.importToXlsx = true

      switch (userRole) {
        case 'user':
          state.permissions.canCreateNewPersona = true
          state.permissions.canEditPersona = true
          state.permissions.canDeletePersona = true
          break

        case 'kpmgadmin':
          state.permissions.menuItems.configuration.capabilityManagement = true
          state.permissions.menuItems.configuration.channels = true
          state.permissions.menuItems.configuration.editTenant = true
          state.permissions.menuItems.configuration.limits = true
          state.permissions.menuItems.configuration.userManagement = true
          state.permissions.menuItems.configuration.globalUserManagement = true
          state.permissions.menuItems.configuration.globalTemplateManagement = true
          state.permissions.menuItems.configuration.exportTenantUsers = true
          state.permissions.menuItems.configuration.templateManagement = true
          state.permissions.menuItems.configuration.globalCapabilityManagement = true

          state.permissions.canCreateNewTenant = true
          state.permissions.canCreateNewAtlas = true
          state.permissions.canCreateNewPersona = true
          state.permissions.canEditPersona = true
          state.permissions.canDeleteAtlas = true
          state.permissions.canDeletePersona = true
          state.permissions.canDeleteTenant = true
          state.permissions.canImportCapabilities = true
          break

        case 'kpmguser':
          state.permissions.menuItems.configuration.capabilityManagement = true
          state.permissions.menuItems.configuration.userManagement = true
          state.permissions.menuItems.configuration.globalCapabilityManagement = true
          state.permissions.menuItems.configuration.templateManagement = true
          state.permissions.menuItems.configuration.channels = true
          state.permissions.menuItems.configuration.limits = true
          state.permissions.menuItems.configuration.editTenant = true

          state.permissions.canCreateNewAtlas = true
          state.permissions.canCreateNewPersona = true
          state.permissions.canEditPersona = true
          state.permissions.canDeleteAtlas = true
          state.permissions.canDeletePersona = true
          break

        case 'poweruser':
          state.permissions.menuItems.configuration.userManagement = true

          state.permissions.canCreateNewAtlas = true
          state.permissions.canCreateNewPersona = true
          state.permissions.canEditPersona = true
          state.permissions.canDeleteAtlas = true
          state.permissions.canDeletePersona = true
          break

        case 'tenantadmin':
          state.permissions.menuItems.configuration.channels = true
          state.permissions.menuItems.configuration.userManagement = true
          state.permissions.menuItems.configuration.templateManagement = true
          state.permissions.menuItems.configuration.editTenant = true
          state.permissions.menuItems.configuration.limits = true

          state.permissions.canCreateNewAtlas = true
          state.permissions.canCreateNewPersona = true
          state.permissions.canEditPersona = true
          state.permissions.canDeleteAtlas = true
          state.permissions.canDeletePersona = true
          break
      }
    },
  },
})
