import { ReactNode } from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export type CentralNotificationContent = ReactNode | undefined

export interface CentralNotification {
  content: CentralNotificationContent
  displayTimeInMs: number
}

export interface CentralNotificationState {
  content: CentralNotificationContent
  hideAtTime: number
  uuid: string
}

const initialState: Array<CentralNotificationState> = []

export const centralNotificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    hideAllNotifications: () => initialState,
    hideNotification: (state, { payload }: PayloadAction<string>) => {
      return state.filter((item) => item.uuid !== payload)
    },
    init: () => initialState,
    showNotification: (state, action: PayloadAction<CentralNotification>) => {
      const guid = uuidv4()
      const hideAt = Date.now() + action.payload.displayTimeInMs
      state.push({
        content: action.payload.content,
        hideAtTime: hideAt,
        uuid: guid,
      })
    },
  },
})
