import { Dispatch, SetStateAction, useCallback } from 'react'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { useMatch } from 'react-router-dom'
import { routeFullPaths } from '@dis/routing'
import { LoadingInfoType } from '@dis/modules/src/ApplicationMenu/ApplicationMenu'
import { selectBacklogFilter } from '@dis/redux/src/backlog/backlogSelectors'
import { selectTableBacklogSort } from '@dis/redux/src/table/tableSelectors'
import { exportDocument } from '@dis/api'
import { convertBacklogFilterToNumbers } from '@dis/modules/src/backlog/utils'

export const useExportXlsx = ({
  setLoadingInfo,
}: {
  setLoadingInfo: Dispatch<SetStateAction<LoadingInfoType>>
}) => {
  const tenantId = useAppSelector(selectSelectedTenantId)
  const journeyId = useAppSelector(selectSelectedJourneyId)
  const localeId = useAppSelector(selectLanguage)
  const isBacklog = !!useMatch(routeFullPaths?.userStory || '')

  const backlogFilter = useAppSelector(selectBacklogFilter)
  const backlogTableSort = useAppSelector(selectTableBacklogSort)

  const handleExportXlsx = useCallback(async () => {
    let response

    if (isBacklog) {
      const params = {
        filter: convertBacklogFilterToNumbers(backlogFilter),
        sort: backlogTableSort,
      }

      response = await exportDocument('backlogXls', { localeId, tenantId }, params, true)
    } else {
      response = await exportDocument('journeyXls', { journeyId, localeId, tenantId })
    }

    setLoadingInfo({ isLoading: false, status: response?.status })
  }, [backlogFilter, backlogTableSort, isBacklog, journeyId, localeId, setLoadingInfo, tenantId])

  return { handleExportXlsx }
}
