import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  link: css`
    text-decoration: none;
    color: ${colors.gray.gray5};
  `,
  root: css`
    display: flex;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    align-items: center;
  `,
  stack: css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
  `,
  text: css`
    margin-inline-start: ${theme.spacing(1)};
    word-break: break-all;
  `,
}
