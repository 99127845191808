import { useMatch } from 'react-router-dom'
import { routeFullPaths } from '@dis/routing'

export const useRouteMatch = () => {
  const isLocalTemplatePreview = !!useMatch(routeFullPaths?.localTemplate || '')
  const isGlobalTemplatePreview = !!useMatch(routeFullPaths?.globalTemplate || '')
  const isPersona = !!useMatch(routeFullPaths?.persona || '')
  const isJourney = !!useMatch(routeFullPaths?.journey || '')
  const isJourneyFolders = !!useMatch(routeFullPaths?.journeyFolder || '')
  const isRecycleBinJourney = !!useMatch(routeFullPaths?.recycleBinJourney || '')
  const isSearch = !!useMatch(routeFullPaths?.search || '')
  const isCapabilityManagement = !!useMatch(routeFullPaths?.capabilityManagement || '')
  const isGlobalCapabilityManagement = !!useMatch(routeFullPaths?.globalCapabilityManagement || '')

  return {
    isCapabilityManagement,
    isGlobalCapabilityManagement,
    isGlobalTemplatePreview,
    isJourney,
    isJourneyFolders,
    isLocalTemplatePreview,
    isPersona,
    isRecycleBinJourney,
    isSearch,
    isTemplatePreview: isLocalTemplatePreview || isGlobalTemplatePreview,
  }
}
