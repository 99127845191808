export enum ApplicationMenuTypes {
  Configuration = 'configuration',
  ExportTenantUsers = 'exportTenantUsers',
  ExportToPdf = 'exportToPdf',
  ExportToXLSX = 'exportToXlsx',
  ExportXML = 'exportXML',
  ImportToXLSX = 'importToXlsx',
  Language = 'language',
  Logout = 'logout',
  LogoutAllDevices = 'logoutAllDevices',
  SendEmail = 'sendEmail',
  ShareJourney = 'shareJourney',
}
