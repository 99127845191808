import { colors, theme } from '@dis/styles'
import { css } from '@emotion/css'

export const styles = {
  closeButton: css`
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  `,
  notification: css`
    height: ${theme.spacing(14)};
    margin-bottom: ${theme.spacing(2)};
    display: inline-flex;
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-shrink: 0;
    color: ${colors.text};
    background-color: ${colors.kpmg.cobaltLight};
    border-radius: ${theme.spacing(2)};
    justify-content: space-between;
  `,
  notificationBox: css`
    position: fixed;
    bottom: ${theme.spacing(38)};
    left: ${theme.spacing(12)};
    height: auto;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
  `,
}
