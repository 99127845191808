import { colors, shadows, theme, typography } from '@dis/styles'
import { css } from '@emotion/css'
import { FOOTER_HEIGHT } from '../journey/common/constants'

export const styles = {
  container: css`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-left: ${theme.spacing(3)};
    min-height: ${FOOTER_HEIGHT};
    color: ${colors.gray.gray40};
    padding-right: ${theme.spacing(3)};
  `,
  copyrightContainer: css`
    display: flex;
    align-items: center;
  `,
  link: css`
    color: ${colors.gray.gray40};
    display: inline-block;
    font-size: ${typography.paragraph.tiny.fontSize};
    line-height: ${typography.paragraph.tiny.lineHeight};
  `,
  wrapper: css`
    background-color: ${colors.background.white};
    box-shadow: inset ${shadows[1]};
    z-index: 1;
  `,
}
