import { useEffect } from 'react'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectIsBrowserOnline, selectIsWebsocketConnected } from '@dis/redux/src/api/selectors'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { selectIsAuthenticated } from '@dis/redux/src/security/selectors'

export const useOnlineStatus = () => {
  const isBrowserOnline = useAppSelector(selectIsBrowserOnline)
  const isWebsocketConnected = useAppSelector(selectIsWebsocketConnected)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const { t } = useTranslation()

  let textKey = ''

  if (isAuthenticated && !isBrowserOnline) {
    textKey = tKeys.api.networkOutage.error
  } else if (isAuthenticated && isBrowserOnline && isWebsocketConnected === false) {
    textKey = tKeys.api.networkOutage.reconnecting
  }

  useEffect(() => {
    if (textKey) {
      const loader = dispatchedActions.centralModalLoader.showModalLoader({
        overlayPriority: 'high',
        text: t(textKey),
      })

      return () => {
        dispatchedActions.centralModalLoader.hideModalLoader(loader)
      }
    }
  }, [textKey, t])

  return
}
