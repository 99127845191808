import { css } from '@emotion/css'
import { colors, theme, typographyFontWeight } from '@dis/styles'

export const styles = {
  buttonHeight: {
    minHeight: '3.25rem',
    minWidth: '11.25rem',
  },
  itemButton: {
    icon: (iconSize: string) => css`
      svg {
        width: ${iconSize};
        height: ${iconSize};
      }
    `,
    iconWithMargin: css`
      margin-inline-end: ${theme.spacing(2)};
    `,
    label: (fontSize: string, color?: string) => css`
      font-size: ${fontSize};
      color: ${color ? color : 'inherit'};
    `,
    root: css`
      text-align: left;
      text-transform: none;
      justify-content: flex-start;
      align-items: center;
      padding: ${theme.spacing(2)} ${theme.spacing(4)};
      font-weight: ${typographyFontWeight.regular};
      border-radius: 0;
    `,
  },
  mainButton: {
    icon: (iconSize: string) => css`
      svg {
        width: ${iconSize};
        height: ${iconSize};
      }
    `,
    iconWithMargin: css`
      margin-inline-end: ${theme.spacing(2)};
    `,
    iconWrapper: css`
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      transition: background-color 0.2s;
      border-radius: ${theme.spacing(0.5)};

      &:hover {
        background-color: ${colors.gray.gray3};
      }
    `,
    root: css`
      min-width: 0;
      text-transform: none;
      padding: 0;
      min-height: 0;
      height: auto;
      border: transparent;
      &:hover {
        background-color: transparent;
      }
      &:active {
        border: transparent;
      }
    `,
  },
  popover: css`
    .MuiPopover-paper {
      display: flex;
      flex-direction: column;
    }
  `,
}
