import { ReactNode } from 'react'
import PersonIcon from '@dis/assets/src/icons/PersonIcon.svg'
import CapabilitiesIcon from '@dis/assets/src/icons/CapabilitiesIcon.svg'
import BinIcon from '@dis/assets/src/icons/BinIcon.svg'
import SearchIcon from '@dis/assets/src/icons/SearchIcon.svg'
import ConfigIcon from '@dis/assets/src/icons/ConfigIcon.svg'
import GlobusIcon from '@dis/assets/src/icons/GlobusIcon.svg'
import SentIcon from '@dis/assets/src/icons/SentIcon.svg'
import InfoIcon from '@dis/assets/src/icons/InfoIcon.svg'
import LogoutIcon from '@dis/assets/src/icons/LogoutIcon.svg'
import { languageCodelist, LANGUAGES } from '@dis/constants'
import { tKeys } from '@dis/languages'
import { RouteNames } from '@dis/routing'
import ExportXML from '@dis/assets/src/icons/ExportXML.svg'
import ShareIcon from '@mui/icons-material/Share'
import ExportPDF from '@dis/assets/src/icons/ExportPDF.svg'
import ExportToXLSX from '@dis/assets/src/icons/ExportToXLSX.svg'
import ImportToXLSX from '@dis/assets/src/icons/ImportToXLSX.svg'
import BacklogIcon from '@dis/assets/src/icons/BacklogIcon.svg'
import { SvgImage } from '@dis/components'
import { colors } from '@dis/styles'

export type SubmenuDataItem<T = string> = {
  navigateTo?: RouteNames
  requireTenant: boolean
  title: string
  type: T
  value?: string
}

export type SubmenuData = Array<SubmenuDataItem>

export type MenuDataItem = {
  icon: ReactNode
  items?: SubmenuData
  navigateTo?: RouteNames
  onClick?: () => void
  requireTenant: boolean
  title: string
  type: keyof typeof applicationMenuItemTypes
}

export const applicationMenuItemTypes = {
  capabilities: 'capabilities',
  configuration: {
    capabilityManagement: 'capabilityManagement',
    channels: 'channels',
    editTenant: 'editTenant',
    exportTenantUsers: 'exportTenantUsers',
    globalCapabilityManagement: 'globalCapabilityManagement',
    globalTemplateManagement: 'globalTemplateManagement',
    globalUserManagement: 'globalUserManagement',
    limits: 'limits',
    templateManagement: 'templateManagement',
    userManagement: 'userManagement',
  },
  exportToPdf: 'exportToPdf',
  exportToXlsx: 'exportToXlsx',
  exportXML: 'exportXML',
  importToXlsx: 'importToXlsx',
  language: LANGUAGES,
  logout: 'logout',
  logoutAllDevices: 'logoutAllDevices',
  personas: 'personas',
  recycleBin: 'recycleBin',
  search: 'search',
  sendEmail: 'sendEmail',
  shareJourney: 'shareJourney',
  tutorial: 'tutorial',
  userStory: 'userStory',
} as const

export const menuData: MenuDataItem[] = [
  {
    icon: <SvgImage src={PersonIcon} />,
    navigateTo: 'personas',
    requireTenant: true,
    title: tKeys.applicationMenu.personasManagement,
    type: 'personas',
  },
  {
    icon: <SvgImage color={colors.gray.gray60} src={CapabilitiesIcon} />,
    navigateTo: 'capabilities',
    requireTenant: true,
    title: tKeys.applicationMenu.connectedCapabilities,
    type: 'capabilities',
  },
  {
    icon: <SvgImage color={colors.gray.gray60} src={BacklogIcon} />,
    navigateTo: 'userStory',
    requireTenant: true,
    title: tKeys.applicationMenu.backlog,
    type: 'userStory',
  },
  {
    icon: <SvgImage color={colors.gray.gray60} src={BinIcon} />,
    navigateTo: 'recycleBin',
    requireTenant: true,
    title: tKeys.applicationMenu.recycleBin,
    type: 'recycleBin',
  },
  {
    icon: <SvgImage color={colors.gray.gray40} src={SearchIcon} />,
    navigateTo: 'search',
    requireTenant: true,
    title: tKeys.applicationMenu.searching,
    type: 'search',
  },
  {
    icon: <SvgImage src={ExportPDF} />,
    requireTenant: true,
    title: tKeys.exportImport.exportToPdf,
    type: 'exportToPdf',
  },
  {
    icon: <ShareIcon />,
    requireTenant: true,
    title: tKeys.applicationMenu.shareJourney,
    type: 'shareJourney',
  },
  {
    icon: <SvgImage src={ExportToXLSX} />,
    requireTenant: true,
    title: tKeys.exportImport.exportToXlsx,
    type: 'exportToXlsx',
  },
  {
    icon: <SvgImage src={ImportToXLSX} />,
    requireTenant: true,
    title: tKeys.exportImport.importToXlsx,
    type: 'importToXlsx',
  },
  {
    icon: <SvgImage src={ExportXML} />,
    requireTenant: true,
    title: tKeys.exportImport.exportXML,
    type: 'exportXML',
  },
  {
    icon: <SvgImage color={colors.gray.gray60} src={ConfigIcon} className="config-icon" />,
    items: [
      {
        navigateTo: 'userManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.userManagement,
        type: 'userManagement',
      },
      {
        navigateTo: 'globalUserManagement',
        requireTenant: false,
        title: tKeys.applicationMenu.subMenu.userManagement,
        type: 'globalUserManagement',
      },
      {
        navigateTo: 'channels',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.manageChannels,
        type: 'channels',
      },
      {
        navigateTo: 'capabilityManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.capabilityManagement,
        type: 'capabilityManagement',
      },
      {
        navigateTo: 'limits',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.tenantLimits,
        type: 'limits',
      },
      {
        navigateTo: 'editTenant',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.manageTenant,
        type: 'editTenant',
      },
      {
        requireTenant: true,
        title: tKeys.exportImport.exportTenantUsers,
        type: 'exportTenantUsers',
      },
      {
        navigateTo: 'templateManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.templateManagement,
        type: 'templateManagement',
      },
      {
        navigateTo: 'globalTemplateManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.templateManagement,
        type: 'globalTemplateManagement',
      },
      {
        navigateTo: 'globalCapabilityManagement',
        requireTenant: false,
        title: tKeys.applicationMenu.subMenu.capabilityManagement,
        type: 'globalCapabilityManagement',
      },
    ] as SubmenuDataItem<keyof ApplicationMenuItemTypes['configuration']>[],
    requireTenant: false,
    title: tKeys.applicationMenu.configuration,
    type: 'configuration',
  },
  {
    icon: <SvgImage src={GlobusIcon} />,
    items: languageCodelist.map((item) => ({
      requireTenant: false,
      title: item.label,
      type: item.value,
      value: item.value,
    })),
    requireTenant: false,
    title: tKeys.applicationMenu.chooseLanguage,
    type: 'language',
  },
  {
    icon: <SvgImage src={SentIcon} />,
    requireTenant: false,
    title: tKeys.applicationMenu.sendFeedback,
    type: 'sendEmail',
  },
  {
    icon: <SvgImage src={InfoIcon} />,
    navigateTo: 'tutorial',
    requireTenant: false,
    title: tKeys.applicationMenu.tutorials,
    type: 'tutorial',
  },
  {
    icon: <SvgImage src={LogoutIcon} />,
    requireTenant: false,
    title: tKeys.applicationMenu.logOutThisDevice,
    type: 'logout',
  },
  {
    icon: <SvgImage src={LogoutIcon} />,
    requireTenant: false,
    title: tKeys.applicationMenu.logOutAllDevices,
    type: 'logoutAllDevices',
  },
]

export type ApplicationMenuItemTypes = typeof applicationMenuItemTypes
