import React, { ReactNode, SyntheticEvent, useCallback } from 'react'
import { Button, Popover, ButtonProps, PopoverProps } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { colors, cx } from '@dis/styles'
import { TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import ThreeDotsIcon from '@dis/assets/src/icons/ThreeDotsIcon.svg'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { useAppSelector } from '@dis/redux'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'

export type Item = {
  disabled?: boolean
  fontSize?: TypographyKeys
  icon?: string
  iconColor?: string
  iconSize?: TypographyKeys
  invisible?: boolean
  isRed?: boolean
  label: string
  labelColor?: string
  onClick: VoidFunction
}

type Props = {
  icon?: string
  iconSize?: TypographyKeys
  items: Array<Item>
  label?: string | ReactNode
  menuOnLeft?: boolean
  onVisibilityChanged?: (visible: boolean) => void
}

// TODO: Create a better name for this component.
// TODO: Use this component in journeys so replace the old one.
export const PopoverWithAnchor = ({
  items,
  label,
  icon,
  iconSize = 'paragraph.large',
  menuOnLeft,
  onVisibilityChanged,
}: Props) => {
  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const handleOpenPopover = useCallback<NonNullable<ButtonProps['onClick']>>(
    (event) => {
      event.stopPropagation()
      handleOpen(event)
      onVisibilityChanged?.(true)
    },
    [handleOpen, onVisibilityChanged],
  )

  const handlePopoverClick = useCallback<NonNullable<PopoverProps['onClick']>>((event) => {
    event.stopPropagation()
    return false
  }, [])

  const handleOnClick = useCallback<(onClickCallback: VoidFunction) => ButtonProps['onClick']>(
    (onClickCallback) => (event) => {
      event.stopPropagation()
      onClickCallback()
      handleClose()
      onVisibilityChanged?.(false)
    },
    [handleClose, onVisibilityChanged],
  )

  const handleClosePopover = useCallback<NonNullable<PopoverProps['onClose']>>(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      handleClose()
      onVisibilityChanged?.(false)
    },
    [handleClose, onVisibilityChanged],
  )

  return (
    <>
      <Button onClick={handleOpenPopover} className={styles.mainButton.root}>
        {!!icon && (
          <span
            className={cx({
              [styles.mainButton.iconWithMargin]: !!label,
            })}>
            <SvgImage
              src={icon}
              color={colors.gray.gray100}
              className={styles.mainButton.icon(getTypographyProp(iconSize, 'fontSize'))}
            />
          </span>
        )}
        <div className={styles.mainButton.iconWrapper}>
          <SvgImage src={ThreeDotsIcon} />
        </div>
      </Button>
      <Popover
        className={styles.popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        onClick={handlePopoverClick}
        onMouseDown={handlePopoverClick}
        anchorOrigin={{
          horizontal: isRtl ? 'right' : 'left',
          vertical: 'top',
        }}
        transformOrigin={
          menuOnLeft
            ? {
                horizontal: isRtl ? 'left' : 'right',
                vertical: 'top',
              }
            : undefined
        }>
        {items
          .filter(({ invisible }) => !invisible)
          .map(
            ({
              label,
              labelColor,
              onClick,
              icon,
              isRed = false,
              iconSize = 'header.h2',
              fontSize = 'paragraph.body',
              disabled,
              iconColor = colors.gray.gray100,
            }) => (
              <Button
                disabled={disabled}
                onClick={handleOnClick(onClick)}
                key={label}
                className={styles.itemButton.root}
                style={styles.buttonHeight}
                color="secondary">
                {!!icon && (
                  <span
                    className={cx({
                      [styles.itemButton.iconWithMargin]: !!label,
                    })}>
                    <SvgImage
                      color={isRed ? colors.semantic.red : iconColor}
                      src={icon}
                      className={styles.itemButton.icon(getTypographyProp(iconSize, 'fontSize'))}
                    />
                  </span>
                )}
                {!!label && (
                  <span
                    className={styles.itemButton.label(
                      getTypographyProp(fontSize, 'fontSize'),
                      isRed ? colors.semantic.red : labelColor,
                    )}>
                    {label}
                  </span>
                )}
              </Button>
            ),
          )}
      </Popover>
    </>
  )
}
