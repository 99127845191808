import { useMemo } from 'react'
import { Modal } from '@dis/components'
import { Input, useForm, yup } from '@dis/form'
import { tKeys } from '@dis/languages'
import { MAX_ATLAS_FOLDER_VISIBLE_LENGTH } from '@dis/modules/src/atlas-folder-journey/utils'
import { useAppSelector } from '@dis/redux'
import { selectTenantLimits } from '@dis/redux/src/tenants/tenantsSelectors'
import { NewAtlasForm } from '@dis/types/src/forms'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  onCreate: (name: string) => void
}

export const CreateAtlasModal = ({ isOpen, onClose, onCreate }: Props) => {
  const { t } = useTranslation()

  const tenantLimits = useAppSelector(selectTenantLimits)
  const maxNameLength = tenantLimits?.maxlen?.others?.atlasname ?? MAX_ATLAS_FOLDER_VISIBLE_LENGTH

  const validationSchema: yup.SchemaOf<NewAtlasForm> = useMemo(() => {
    return yup.object().shape({
      name: yup.string().max(maxNameLength).required(),
    })
  }, [maxNameLength])

  const { control, handleSubmit } = useForm<NewAtlasForm>({ validationSchema })

  const onSubmit = handleSubmit((data: NewAtlasForm) => {
    onCreate(data.name)
  })

  return (
    <Modal
      onCrossBtnClick={onClose}
      isOpen={isOpen}
      title={t(tKeys.atlasesPreview.title)}
      onPrimaryBtnClick={onSubmit}
      primaryBtnText={t(tKeys.common.submit)}
      height="auto">
      <Input
        required
        name="name"
        fixedLabel
        variant="outlined"
        hoverUnderline={false}
        label={t(tKeys.common.name)}
        formControl={control}
        placeholder={t(tKeys.atlasesPreview.placeholder)}
        maxLength={maxNameLength}
        isLimitVisible
        showLimitOnTop
      />
    </Modal>
  )
}
