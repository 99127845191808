import { css } from '@emotion/css'
import { theme } from '@dis/styles'
import { typography } from '@dis/styles/src/theme'

export const styles = {
  iconsContainer: css`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 19px;
    min-width: 19px;
  `,
  languageContainer: css`
    align-items: center;
    display: flex;
    justify-content: end;
    width: 35px;
  `,
  loader: {
    modalBg: css`
      justify-content: center;
      align-items: center;
      background: rgba(33, 33, 33, 0.4);
      pointer-events: auto;
      position: fixed;
      z-index: 1200;
      inset: 0px;
      display: flex;
    `,
    modalBox: css`
      height: 58px;
      min-width: 133px;
      display: flex;
      padding: 15px;
      background-color: #fff;
      border-radius: 7.5px;
      box-shadow: 0 0 30px #25262a;
      align-items: center;
    `,
    modalText: css`
      color: #25262a;
      white-space: nowrap;
    `,
    root: css`
      .MuiCircularProgress-root {
        height: 28px !important;
        width: 28px !important;
        color: #4a90e2;
        margin-right: 8px;
      }
    `,
  },
  menu: css`
    z-index: ${theme.zIndex.snackbar};
    .MuiButtonBase-root {
      align-items: center;
      min-height: 1.5rem;
      padding-bottom: ${theme.spacing(0)};
      padding-top: ${theme.spacing(0)};
    }
  `,
  nestedMenu: css`
    z-index: ${theme.zIndex.snackbar};
  `,
  nestedMenuItem: css`
    padding-inline-start: ${theme.spacing(4)};
    padding-inline-end: ${theme.spacing(4)};
    .config-icon {
      margin-right: ${theme.spacing(-1.2)};
    }

    .MuiTypography-root {
      padding: ${theme.spacing(0)};
      font-size: ${typography.paragraph.small.fontSize};
    }
    .MuiButtonBase-root {
      min-height: 1.5rem;
      padding-bottom: ${theme.spacing(0)};
      padding-top: ${theme.spacing(0)};
    }
    .MuiBox-root {
      align-items: center;
      justify-content: center;
    }
  `,
}
