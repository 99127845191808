import {
  ListenerEffectAPI,
  TypedStartListening,
  combineReducers,
  configureStore,
  createListenerMiddleware,
} from '@reduxjs/toolkit'

import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { generalErrorModals } from '@dis/modals/src/generalErrorModals'
import { apiSlice } from './api/apiSlice'
import { centralModalLoaderSlice } from './centralModalLoader/centralModalLoaderSlice'
import { centralModalDialogSlice } from './centralModalDialog/centralModalDialogSlice'
import { journeySlice } from './journeys/journeysSlice'
import { personasSlice } from './personas/personasSlice'
import { securitySlice } from './security/securitySlice'
import { tenantsSlice } from './tenants/tenantsSlice'
import { userSlice } from './user/userSlice'
import { userManagementSlice } from './userManagement/userManagementSlice'
import { localApiSlice } from './api/localApiSlice'
import { generalSlice } from './general/slice'
import { searchSlice } from './search/searchSlice'
import { setupSecurityListeners } from './security/securityListeners'
import { mapDispatchToActions } from './utils'
import { backlogSlice } from './backlog/backlogSlice'
import { tableSlice } from './table/tableSlice'
import { centralNotificationSlice } from './notification/notificationSlice'

export const actions = {
  api: apiSlice.actions,
  backlog: backlogSlice.actions,
  centralModalDialog: centralModalDialogSlice.actions,
  centralModalLoader: centralModalLoaderSlice.actions,
  centralNotification: centralNotificationSlice.actions,
  general: generalSlice.actions,
  journeys: journeySlice.actions,
  personas: personasSlice.actions,
  search: searchSlice.actions,
  security: securitySlice.actions,
  table: tableSlice.actions,
  tenants: tenantsSlice.actions,
  user: userSlice.actions,
  userManagement: userManagementSlice.actions,
}

const rootReducer = combineReducers({
  api: apiSlice.reducer,
  backlog: backlogSlice.reducer,
  centralModalDialog: centralModalDialogSlice.reducer,
  centralModalLoader: centralModalLoaderSlice.reducer,
  centralNotification: centralNotificationSlice.reducer,
  general: generalSlice.reducer,
  journeys: journeySlice.reducer,
  localApi: localApiSlice.reducer,
  personas: personasSlice.reducer,
  search: searchSlice.reducer,
  security: securitySlice.reducer,
  table: tableSlice.reducer,
  tenants: tenantsSlice.reducer,
  user: userSlice.reducer,
  userManagement: userManagementSlice.reducer,
})

export const listeners = [setupSecurityListeners]

const listenerMiddlewareInstance = createListenerMiddleware({
  onError: (error) => {
    console.error('Saga error:', error)

    dispatchedActions.centralModalLoader.forceHideModalLoader()
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    generalErrorModals.default(error as any)
  },
})

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    // serializableCheck false is necessary due to callbacks in the central modal config
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      localApiSlice.middleware,
      listenerMiddlewareInstance.middleware,
    )
  },
  reducer: rootReducer,
})

export type Store = typeof store
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>

export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening

export const dispatchedActions = mapDispatchToActions<typeof actions>(actions, store)

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
