import { css } from '@emotion/css'
import { colors, shadowClasses, theme } from '@dis/styles'

export const styles = {
  animation: {
    height: '50vh',
    minHeight: 0,
    width: '50vh',
  },
  background: css`
    background: rgba(33, 33, 33, 0.4);
  `,
  isVisible: css`
    opacity: 1;
    z-index: ${theme.zIndex.modal};
    transition: z-index 0s linear 0s, opacity 0.5s linear 0s;
  `,
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: -100;
    background-color: ${colors.transparent};
    transition: opacity 0.5s linear 0s, z-index 0s linear 0.5s;
  `,
  text: css`
    color: ${colors.text};
    white-space: nowrap;
  `,
  textBox: css`
    ${shadowClasses[17]};
    display: flex;
    padding: ${theme.spacing(4)};
    background-color: ${colors.background.white};
    border-radius: ${theme.spacing(2)};
    align-items: center;

    .MuiCircularProgress-root {
      height: 1.75rem !important;
      width: 1.75rem !important;
      color: ${colors.kpmg.cobaltBlue};
      margin-inline-end: ${theme.spacing(4)};
    }
  `,
}
