export * from './authConfig'

export * from './common'

export * from './languages'

export enum ErrorCode {
  GeneralError = 'GENERAL_ERROR',
  InvalidConfigJson = 'INVALID_CONFIG_JSON',
  NetworkError = 'NETWORK_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  Unauthorized = 'UNAUTHORIZED',
  UnavailableConfigJson = 'UNAVAILABLE_CONFIG_JSON',
}
