import { useCallback, useEffect, useMemo } from 'react'
import { DashboardContainer } from '@dis/components'
import { useNavTo } from '@dis/hooks'
import { useAtlasPermissions } from '@dis/hooks/src/useAtlasPermissions'
import { useTenantDashboard } from '@dis/hooks/src/useSubscribe'
import { useAppSelector } from '@dis/redux'
import { selectPermissions } from '@dis/redux/src/security/selectors'
import { useCreateAtlas } from '@dis/hooks/src/useCreate'
import { tKeys } from '@dis/languages'
import { useHandleOpen } from '@dis/hooks/src/useHandleOpen'
import { useTranslation } from 'react-i18next'
import { CreateAtlasModal } from './CreateAtlasModal'
import { AtlasFolderJourneyGrid } from '../atlas-folder-journey/AtlasFolderJourneyGrid'
import { AtlasFolderJourney } from '../atlas-folder-journey/AtlasFolderJourney'

export const AtlasesPreview = () => {
  const { navigate, navigateFactory } = useNavTo()
  const { handleClose, handleOpen, open } = useHandleOpen()
  const { t } = useTranslation()

  const { sendCreate, createData } = useCreateAtlas()

  const { dashboardData } = useTenantDashboard({
    autoRefetch: {
      create: true,
      delete: true,
    },
  })

  const { canCreateNewAtlas } = useAppSelector(selectPermissions)

  const atlases = dashboardData?.atlases || undefined

  const atlasIds = useMemo(
    () => dashboardData?.atlases?.map((atlas) => atlas.id) || [],
    [dashboardData?.atlases],
  )

  const atlasPermissions = useAtlasPermissions({
    atlasIds,
  })

  const onAtlasClick = useCallback(
    (id: number) => () => {
      navigate('atlas', { atlasId: id })
    },
    [navigate],
  )

  useEffect(() => {
    if (createData) {
      navigate('atlas', { atlasId: createData?.id })
    }
  }, [createData, navigate])

  const filteredAtlases = atlases?.filter((atlas) => atlasPermissions[atlas.id].canRead) || []

  return (
    <>
      <CreateAtlasModal isOpen={open} onClose={handleClose} onCreate={sendCreate} />
      <DashboardContainer
        gridArea="Atlases"
        isLoader={!atlases}
        canCreate={canCreateNewAtlas}
        navigate={navigateFactory('atlases')}
        textButtonTitle={tKeys.atlasesPreview.showAll}
        sendCreate={handleOpen}
        title={tKeys.common.atlases}>
        {!!atlases && !!filteredAtlases.length && (
          <AtlasFolderJourneyGrid mode="dashboard">
            {atlases?.map((atlas) => (
              <AtlasFolderJourney
                created={atlas.created}
                key={atlas.id}
                name={!atlas.name ? t(tKeys.common.newAtlas) : atlas.name}
                onClick={onAtlasClick(atlas.id)}
                type="atlas"
              />
            ))}
          </AtlasFolderJourneyGrid>
        )}
      </DashboardContainer>
    </>
  )
}
