import { LANGUAGES } from '@dis/constants'

export interface DsStyleSheet {
  [index: string]: React.CSSProperties
}

export type Language = keyof typeof LANGUAGES

export type CompProps<Comp extends (props: any) => any> = NonNullable<Parameters<Comp>[0]>

export type CommonSvgProps = {
  color?: string
  dimension?: string
  height?: string
  title?: string
  width?: string
}

export type SortDirection = 'asc' | 'desc'

export enum ViewEnum {
  Card = 'card',
  Table = 'table',
}
