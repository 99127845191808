import { useMemo } from 'react'
import { CircularProgress } from '@mui/material'
import { useAppSelector } from '@dis/redux'
import { cx } from '@emotion/css'
import { LoadingAnimation, Paragraph } from '@dis/components'
import { selectCentralModalLoaderItems } from '@dis/redux/src/centralModalLoader/selectors'
import { styles } from './styles'

export const CentralModalLoader = () => {
  const items = useAppSelector(selectCentralModalLoaderItems)

  const isAnimationVisible = !!items.length

  const textItem = useMemo(() => [...items].reverse().find(({ text }) => !!text), [items])

  return (
    <div
      className={cx(styles.root, {
        [styles.isVisible]: isAnimationVisible,
        [styles.background]: !!textItem,
      })}>
      {!textItem && (
        <LoadingAnimation outerStyle={styles.animation} isAnimated={isAnimationVisible} />
      )}
      {!!textItem && (
        <div className={styles.textBox}>
          <CircularProgress />
          <Paragraph className={styles.text}>{textItem.text}</Paragraph>
        </div>
      )}
    </div>
  )
}
