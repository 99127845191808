import { useCallback, useState } from 'react'
import { IconButton, Menu } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import HamburgerMenuIcon from '@dis/assets/src/icons/HamburgerMenuIcon.svg'
import { NotificationModal, Paragraph, SvgImage } from '@dis/components'
import { tKeys } from '@dis/languages'
import { reloadApp } from '@dis/utils/src/url'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@dis/redux'
import { selectIsFeatureConnectedCapabilitiesAllowed } from '@dis/redux/src/security/selectors'
import { ApplicationMenuItem } from './ApplicationMenuItem'
import { styles } from './styles'
import { Loader } from './Loader'
import { menuData } from './constants'

export type LoadingInfoType = {
  fileName?: string
  isImport?: boolean
  isLoading: boolean
  status?: number
  translation?: string
}

const loadingInfoDefault: LoadingInfoType = {
  fileName: undefined,
  isImport: undefined,
  isLoading: false,
  status: undefined,
  translation: undefined,
}

export const ApplicationMenu = () => {
  const [loadingInfo, setLoadingInfo] = useState<LoadingInfoType>(loadingInfoDefault)

  const isFeatureConnectedCapabilitiesAllowed = useAppSelector(
    selectIsFeatureConnectedCapabilitiesAllowed,
  )

  const filteredMenuData = menuData.filter(
    (data) => data.navigateTo !== 'capabilities' || isFeatureConnectedCapabilitiesAllowed,
  )

  const handleCloseModal = useCallback(() => {
    setLoadingInfo(loadingInfoDefault)
  }, [])

  const { anchorEl, handleOpen, handleClose } = useAnchor()
  const { t } = useTranslation()

  const open = !!anchorEl

  if (loadingInfo.isLoading) {
    return <Loader title={loadingInfo.translation} />
  }

  if (loadingInfo.status === 200 && loadingInfo.isImport) {
    return (
      <NotificationModal
        isOpen
        onCrossBtnClick={reloadApp}
        onPrimaryBtnClick={reloadApp}
        primaryBtnText={t(tKeys.common.accept)}
        severity="success"
        title={t(tKeys.successModal.journeys.title)}>
        <Paragraph
          variant="medium"
          tKey={t(tKeys.successModal.journeys.description)}
          tValues={{
            name: loadingInfo.fileName,
          }}
        />
      </NotificationModal>
    )
  }

  if (loadingInfo.status && loadingInfo.status !== 200 && !loadingInfo.isImport) {
    return (
      <NotificationModal
        isOpen
        onCrossBtnClick={handleCloseModal}
        onPrimaryBtnClick={handleCloseModal}
        primaryBtnText={t(tKeys.common.accept)}
        severity="error"
        title={t(tKeys.errorModal.error)}>
        {t(tKeys.errorModal.journeys.description.export, { name: loadingInfo.status })}
      </NotificationModal>
    )
  }

  if (loadingInfo.status && loadingInfo.status !== 200 && loadingInfo.isImport) {
    return (
      <NotificationModal
        isOpen={true}
        onCrossBtnClick={handleCloseModal}
        onPrimaryBtnClick={handleCloseModal}
        primaryBtnText={t(tKeys.common.accept)}
        severity="error"
        title={t(tKeys.errorModal.error)}>
        {t(tKeys.errorModal.journeys.description.import, { name: loadingInfo.status })}
      </NotificationModal>
    )
  }

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SvgImage src={HamburgerMenuIcon} />
      </IconButton>
      <Menu
        className={styles.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}>
        {filteredMenuData.map((item) => (
          <ApplicationMenuItem
            setLoadingInfo={setLoadingInfo}
            key={`${item.navigateTo}_${item.title}`}
            menuItem={item}
            opened={!!anchorEl}
            handleClose={handleClose}
          />
        ))}
      </Menu>
    </div>
  )
}
