import { languages } from '@dis/languages/src/translations/languages'

export type Languages = {
  [K in keyof typeof languages]: K
}

export type LanguagesUnion = keyof typeof languages

export const LANGUAGES = ((): Languages => {
  const ret: Record<string, string> = {}

  Object.keys(languages).forEach((lng) => {
    ret[lng] = lng
  })

  return ret as Languages
})()

export const languageCodelist = Object.entries(languages).map(([value, label]) => ({
  label,
  value,
}))
