export const scrollbarWidth = '11px' as const

export const dropdownStyles = {
  container: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px solid white',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRadius: '0px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '0px solid white',
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        borderRadius: '0px',
      },
    },
    '& fieldset': { height: 20, top: 20 },
  },
  font: { fontSize: '0.8rem' },
  itemWidth: {
    style: {
      width: 140,
    },
  },
}
