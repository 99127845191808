import { ReactNode, Suspense } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import {
  Login,
  TestPage,
  Backlog,
  Tutorial,
  Personas,
  PersonaDetail,
  LandingPage,
  RecycleBin,
  Folder,
  Error,
  Journey,
  TenantLimits,
  AtlasDetail,
  NewJourney,
  ListOfAtlases,
  ChannelManagement,
  ManagementOfTenant,
  Search,
  ConnectedCapabilities,
  UserManagement,
  ListOfJourneys,
  TemplateManagement,
} from '@dis/screens'
import AtlasIcon from '@dis/assets/src/icons/AtlasIcon.svg'
import PersonaManagementIcon from '@dis/assets/src/icons/PersonaManagementIcons.svg'
import NewFolderIcon from '@dis/assets/src/icons/NewFolderIcon.svg'
import HomeIcon from '@dis/assets/src/icons/HomeIcon.svg'
import PersonaNavbarIcon from '@dis/assets/src/icons/PersonaNavbarIcon.svg'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import TenantIcon from '@dis/assets/src/icons/TenantIcon.svg'
import TemplateIcon from '@dis/assets/src/icons/TemplateIcon.svg'
import { ComponentsLoader, SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { Tenants } from '@dis/screens/src/Tenants/Tenants'
import { generateRouteObjects, setRoutes } from './utils'
import { RouteHandleCrumbContent } from './types'
import { styles } from './styles'

const lazy = (element: ReactNode) => <Suspense fallback={<ComponentsLoader />}>{element}</Suspense>

const routeObjects = generateRouteObjects()

export const getRouter = (rootElement: ReactNode) => {
  const routes = createRoutesFromElements(
    <Route
      element={rootElement}
      errorElement={<Error />}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={HomeIcon} />,
          routeToKey: 'tenants',
        }),
        name: routeObjects.tenants.name,
      }}>
      <Route index element={<Tenants />} />

      <Route
        path={routeObjects.login.path}
        element={<Login />}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'login' }),
          name: routeObjects.login.name,
        }}
      />

      <Route
        path={routeObjects.test.path}
        element={lazy(<TestPage />)}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'test' }),
          name: routeObjects.test.name,
        }}
      />

      <Route
        path={routeObjects.tutorial.path}
        element={lazy(<Tutorial />)}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'tutorial' }),
          name: routeObjects.tutorial.name,
        }}
      />

      <Route path={routeObjects.tenants.path} errorElement={<Error />}>
        <Route index element={<Navigate to="/" />} />

        <Route
          path={routeObjects.tenant.path}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={TenantIcon} />,
              routeToKey: 'tenant',
            }),
            name: routeObjects.tenant.name,
          }}>
          <Route index element={lazy(<LandingPage />)} />

          <Route
            path={routeObjects.capabilities.path}
            element={lazy(<ConnectedCapabilities />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'capabilities' }),
              name: routeObjects.capabilities.name,
            }}
          />

          <Route
            path={routeObjects.recycleBin.path}
            element={lazy(<RecycleBin />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'recycleBin' }),
              name: routeObjects.recycleBin.name,
            }}
          />

          <Route path={routeObjects.journeys.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.recycleBinJourney.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: (
                    <SvgImage
                      src={JourneyIcon}
                      color={colors.gray.gray60}
                      className={styles.routeIcon}
                      width="24px"
                      height="24px"
                    />
                  ),
                  routeToKey: 'recycleBinJourney',
                }),
                name: routeObjects.recycleBinJourney.name,
              }}
            />
          </Route>

          <Route path={routeObjects.atlases.path}>
            <Route
              index
              element={lazy(<ListOfAtlases />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: (
                    <SvgImage
                      src={AtlasIcon}
                      color={colors.gray.gray60}
                      className={styles.routeIcon}
                      width="24px"
                      height="24px"
                    />
                  ),
                  routeToKey: 'atlases',
                }),
                name: routeObjects.atlases.name,
              }}
            />

            <Route
              path={routeObjects.atlas.path}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: (
                    <SvgImage
                      src={AtlasIcon}
                      color={colors.gray.gray60}
                      className={styles.routeIcon}
                      width="24px"
                      height="24px"
                    />
                  ),
                  routeToKey: 'atlas',
                }),
                name: routeObjects.atlas.name,
              }}>
              <Route index element={lazy(<AtlasDetail />)} />

              <Route path={routeObjects.newJourney.path}>
                <Route
                  index
                  element={lazy(<NewJourney />)}
                  handle={{
                    crumb: (): RouteHandleCrumbContent => ({
                      icon: (
                        <SvgImage
                          src={JourneyIcon}
                          color={colors.gray.gray60}
                          className={styles.routeIcon}
                          width="24px"
                          height="24px"
                        />
                      ),
                      routeToKey: 'newJourneyAtlas',
                    }),
                    name: routeObjects.newJourneyAtlas.name,
                  }}
                />
              </Route>

              <Route path={routeObjects.folders.path}>
                <Route index element={<Navigate to="/" />} />
                <Route
                  path={routeObjects.folder.path}
                  handle={{
                    crumb: (): RouteHandleCrumbContent => ({
                      icon: <SvgImage src={NewFolderIcon} />,
                      routeToKey: 'folder',
                    }),
                    name: routeObjects.folder.name,
                  }}>
                  <Route index element={lazy(<Folder />)} />
                  <Route path={routeObjects.journeys.path}>
                    <Route index element={<Navigate to="/" />} />
                    <Route
                      path={routeObjects.journeyFolder.path}
                      element={lazy(<Journey />)}
                      handle={{
                        crumb: (): RouteHandleCrumbContent => ({
                          icon: (
                            <SvgImage
                              src={JourneyIcon}
                              color={colors.gray.gray60}
                              className={styles.routeIcon}
                              width="24px"
                              height="24px"
                            />
                          ),
                          routeToKey: 'journeyFolder',
                        }),
                        name: routeObjects.journeyFolder.name,
                      }}
                    />
                  </Route>

                  <Route path={routeObjects.newJourney.path}>
                    <Route
                      index
                      element={lazy(<NewJourney />)}
                      handle={{
                        crumb: (): RouteHandleCrumbContent => ({
                          icon: (
                            <SvgImage
                              src={JourneyIcon}
                              color={colors.gray.gray60}
                              className={styles.routeIcon}
                              width="24px"
                              height="24px"
                            />
                          ),
                          routeToKey: 'newJourneyFolder',
                        }),
                        name: routeObjects.newJourneyFolder.name,
                      }}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path={routeObjects.journeys.path}>
                <Route index element={<Navigate to="/" />} />
                <Route
                  path={routeObjects.journey.path}
                  element={lazy(<Journey />)}
                  handle={{
                    crumb: (): RouteHandleCrumbContent => ({
                      icon: (
                        <SvgImage
                          src={JourneyIcon}
                          color={colors.gray.gray60}
                          className={styles.routeIcon}
                          width="24px"
                          height="24px"
                        />
                      ),
                      routeToKey: 'journey',
                    }),
                    name: routeObjects.journey.name,
                  }}
                />
              </Route>
            </Route>
          </Route>

          <Route
            path={routeObjects.personas.path}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={PersonaManagementIcon} />,
                routeToKey: 'personas',
              }),
              name: routeObjects.personas.name,
            }}>
            <Route index element={lazy(<Personas />)} />
            <Route
              path={routeObjects.persona.path}
              element={lazy(<PersonaDetail />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={PersonaNavbarIcon} />,
                  routeToKey: 'persona',
                }),
                name: routeObjects.persona.name,
              }}
            />
          </Route>

          <Route
            path={routeObjects.backlog.path}
            element={lazy(<h2>backlog</h2>)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                routeToKey: 'backlog',
              }),
              name: routeObjects.backlog.name,
            }}
          />

          <Route
            path={routeObjects.search.path}
            element={<Search />}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                routeToKey: 'search',
              }),
              name: routeObjects.search.name,
            }}
          />

          <Route path={routeObjects.globalTemplatePreview.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.globalTemplate.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'globalTemplate' }),
                name: routeObjects.globalTemplate.name,
              }}
            />
          </Route>

          <Route path={routeObjects.localTemplatePreview.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.localTemplate.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'localTemplate' }),
                name: routeObjects.localTemplate.name,
              }}
            />
          </Route>

          <Route
            path={routeObjects.userStory.path}
            element={lazy(<Backlog />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                routeToKey: 'userStory',
              }),
              name: routeObjects.userStory.name,
            }}
          />

          <Route
            path={routeObjects.listOfJourneys.path}
            element={lazy(<ListOfJourneys />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: (
                  <SvgImage
                    src={JourneyIcon}
                    color={colors.gray.gray60}
                    className={styles.routeIcon}
                    width="24px"
                    height="24px"
                  />
                ),
                routeToKey: 'listOfJourneys',
              }),
              name: routeObjects.listOfJourneys.name,
            }}
          />

          <Route
            path={routeObjects.newJourney.path}
            element={lazy(<NewJourney />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: (
                  <SvgImage
                    src={JourneyIcon}
                    color={colors.gray.gray60}
                    className={styles.routeIcon}
                    width="24px"
                    height="24px"
                  />
                ),
                routeToKey: 'newJourney',
              }),
              name: routeObjects.newJourney.name,
            }}
          />

          <Route
            path={routeObjects.administration.path}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                routeToKey: 'administration',
              }),
              name: routeObjects.administration.name,
            }}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.limits.path}
              element={lazy(<TenantLimits />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'limits',
                }),
                name: routeObjects.limits.name,
              }}
            />

            <Route
              path={routeObjects.channels.path}
              element={lazy(<ChannelManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'channels',
                }),
                name: routeObjects.channels.name,
              }}
            />
            <Route
              path={routeObjects.userManagement.path}
              element={lazy(<UserManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'userManagement',
                }),
                name: routeObjects.userManagement.name,
              }}
            />
            <Route
              path={routeObjects.capabilityManagement.path}
              element={lazy(<ConnectedCapabilities />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'capabilityManagement',
                }),
                name: routeObjects.capabilityManagement.name,
              }}
            />

            <Route
              path={routeObjects.templateManagement.path}
              element={lazy(<TemplateManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={TemplateIcon} width="24px" height="24px" />,
                  routeToKey: 'templateManagement',
                }),
                name: routeObjects.templateManagement.name,
              }}
            />

            <Route
              path={routeObjects.editTenant.path}
              element={lazy(<ManagementOfTenant />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'editTenant',
                }),
                name: routeObjects.editTenant.name,
              }}
            />
          </Route>
        </Route>

        <Route index element={<Navigate to="/" />} />
      </Route>

      <Route
        path={routeObjects.administration.path}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({
            routeToKey: 'administration',
          }),
          name: routeObjects.administration.name,
        }}>
        <Route index element={<Navigate to="/" />} />

        <Route
          path={routeObjects.globalUserManagement.path}
          element={lazy(<UserManagement />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              routeToKey: 'globalUserManagement',
            }),
            name: routeObjects.globalUserManagement.name,
          }}
        />

        <Route
          path={routeObjects.globalTemplateManagement.path}
          element={lazy(<TemplateManagement />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              routeToKey: 'globalTemplateManagement',
            }),
            name: routeObjects.globalTemplateManagement.name,
          }}
        />

        <Route
          path={routeObjects.globalCapabilityManagement.path}
          element={lazy(<ConnectedCapabilities />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              routeToKey: 'globalCapabilityManagement',
            }),
            name: routeObjects.globalCapabilityManagement.name,
          }}
        />
      </Route>
    </Route>,
  )

  setRoutes(routes)

  return createBrowserRouter(routes)
}
