import { useCallback } from 'react'
import { Grid, Hidden } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { Paragraph } from '@dis/components'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import {
  selectIsChangeRunning,
  selectJourneysData,
  selectSelectedJourneyId,
} from '@dis/redux/src/journeys/journeysSelectors'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { useChangePublicationStatus } from '@dis/hooks/src/useChange'
import { StatusButton } from './StatusButton'
import { styles } from './styles'

export const PublicationStatus = () => {
  const journeyData = useAppSelector(selectJourneysData)
  const isJourneyChangeRunning = useAppSelector(selectIsChangeRunning)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)
  const selectedTenantId = useAppSelector(selectSelectedTenantId)

  const { t } = useTranslation()

  const onChangeData = useCallback(() => {
    dispatchedActions.journeys.setForceRefetchFlag(true)
  }, [])

  const { send: sendChangePublicationStatus } = useChangePublicationStatus({
    onData: onChangeData,
  })

  const journeyInfo = journeyData?.subscribe.info
  const published = !!journeyInfo?.published
  const deleted = !!journeyInfo?.deleted

  const onClickPublish = useCallback(() => {
    if (selectedJourneyId && selectedTenantId && !deleted && journeyInfo) {
      sendChangePublicationStatus({ ...journeyInfo, published: !published })
    } else {
      console.error('onClickPublish: Missing required attributes')
    }
  }, [
    deleted,
    journeyInfo,
    published,
    selectedJourneyId,
    selectedTenantId,
    sendChangePublicationStatus,
  ])

  if (!journeyData) return null

  return (
    <Grid container direction="row" mr={15}>
      <Hidden lgUp>
        <StatusButton isDeleted={deleted} isDone={published} onClick={onClickPublish} />
      </Hidden>
      <Hidden lgDown>
        <Paragraph variant="medium" className={styles.statusContainer}>
          {t(tKeys.common.status)}:
        </Paragraph>
        <StatusButton
          disabled={isJourneyChangeRunning}
          isDone={published}
          isDeleted={deleted}
          onClick={onClickPublish}
          withText
        />
      </Hidden>
    </Grid>
  )
}
